export const getAccessToken = () => localStorage.getItem('localAccessToken') ?? sessionStorage.getItem('sessionAccessToken');

export const setAuthToken = (data, isLocalStorage) => isLocalStorage ? 
  localStorage.setItem('localAccessToken', data) : 
  sessionStorage.setItem('sessionAccessToken', data);


export const resetAuthData = () => {
  localStorage.removeItem('localAccessToken');
  sessionStorage.removeItem('sessionAccessToken');
};

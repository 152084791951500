import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import { setCourierFbToken } from 'store/courier/actions';
import { notification } from "antd";
import { getAccessToken } from 'services/authService';

import signalFile from 'sound/Sound_11086.wav';
import useSound from 'use-sound';

const firebase = initializeApp({
  apiKey: "AIzaSyCpnWEJjkh-49El3LmQ5Or3Z1Y2msH60ec",
  authDomain: "cron-admin-panel.firebaseapp.com",
  projectId: "cron-admin-panel",
  storageBucket: "cron-admin-panel.appspot.com",
  messagingSenderId: "834145777286",
  appId: "1:834145777286:web:043fa7b67cb98cba9b4307",
  measurementId: "G-ELLXDLCWBC"
});

const messaging = getMessaging(firebase);

// // Callback fired if Instance ID token is updated.
// messaging.onTokenRefresh(() => {
//   messaging.getFirebaseToken()
//     .then((refreshedToken) => {
//       console.log('Token refreshed');
//       // Send Instance ID token to app server.
//       sendTokenToServer(refreshedToken);
//     })
//     .catch((error) => {
//       showError('Unable to retrieve refreshed token', error);
//     });
// });

const useFirebase = () => {
  const [play] = useSound(signalFile);
  const dispatch = useDispatch();
  const {userData} = useSelector(({user}) => user);

  const showError = (error, error_data) => {
    if (typeof error_data !== "undefined") {
      console.error(error, error_data);
    } else {
      console.error(error);
    }
  };

  const isTokenSentToServer = (currentToken) => {
    return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;
  };

  const setTokenSentToServer = (currentToken) => {
    if (currentToken) {
      window.localStorage.setItem('sentFirebaseMessagingToken', currentToken);
    } else {
      window.localStorage.removeItem('sentFirebaseMessagingToken');
    }
  };

  const sendTokenToServer = (currentToken) => {
    if (!isTokenSentToServer(currentToken)) {
      console.log('Sending token to server...');
      dispatch(setCourierFbToken({token: currentToken}))
      setTokenSentToServer(currentToken);
      console.log('The token is installed on the server!')
    } else {
      console.log('Token already sent to server so won\'t send it again unless it changes');
    }
  };

  const getFbToken = async () => {
    try {
      const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FB_VAPID_KEY });
      sendTokenToServer(token);
      return token
    } catch (error) {
      console.log("An error occurred while retrieving token. ", error);
    }
  };

  const requestPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        getFbToken()
      }  
    }) 
    .catch((error) => {
      showError('Unable to get permission to notify', error);
    });
  };

  const handleClick = useCallback(() => play(), [play]);

  onMessage(messaging, (payload) => {
    if(payload.notification) {
      const isAssigned = !!payload.notification.body.match(/назначе/);
      notification.info({
        message: isAssigned ? 'Новый Заказ!' : 'Заказ снят!',
        description: payload.notification.body,
        duration: 6
      });
      return handleClick();
    }
  });

  const deleteFbToken = async () => {
    const authToken = getAccessToken();
    if(!authToken) {
      try {
        const token = localStorage.getItem("sentFirebaseMessagingToken");
        if(token) {
          await deleteToken(messaging)
          .then(() => {
            console.log('Token deleted');
            setTokenSentToServer(false);
          })
          .catch((error) => {
            showError('Unable to delete token', error);
          });
        }
      } catch (error) {
        showError('Error retrieving Instance ID token', error);
      }
    }
  };

  useEffect(() => {
    if (
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'localStorage' in window &&
      'fetch' in window &&
      'postMessage' in window &&
      firebase && userData?.role === "Courier"
    ) {
      if (Notification.permission === 'granted') {
        getFbToken();
      } else {
        requestPermission();
      }
    } else {
      if (!('Notification' in window)) {
          showError('Notification not supported');
      } else if (!('serviceWorker' in navigator)) {
          showError('ServiceWorker not supported');
      } else if (!('localStorage' in window)) {
          showError('LocalStorage not supported');
      } else if (!('fetch' in window)) {
          showError('fetch not supported');
      } else if (!('postMessage' in window)) {
          showError('postMessage not supported');
      }
      deleteFbToken();
    }
  }, [firebase, userData])
};

export default useFirebase;
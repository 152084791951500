import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';

import store from './store/store';
import App from './components/App';

import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ruRU}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

import {
  GET_COURIERS_REQUEST,
  GET_COURIERS_SUCCESS,
  GET_COURIERS_ERROR,
  ADD_COURIER_REQUEST,
  ADD_COURIER_SUCCESS,
  ADD_COURIER_ERROR,
  EDIT_COURIER_REQUEST,
  EDIT_COURIER_SUCCESS,
  EDIT_COURIER_ERROR,
  DELETE_COURIER_REQUEST,
  DELETE_COURIER_SUCCESS,
  DELETE_COURIER_ERROR,
  GET_COURIER_ORDERS_REQUEST,
  GET_COURIER_ORDERS_SUCCESS,
  GET_COURIER_ORDERS_ERROR,
  ASSIGN_ORDER_COURIER_REQUEST,
  ASSIGN_ORDER_COURIER_SUCCESS,
  ASSIGN_ORDER_COURIER_ERROR,
  RESET_COURIER_ORDER_REQUEST,
  RESET_COURIER_ORDER_SUCCESS,
  RESET_COURIER_ORDER_ERROR,
} from '../actions';

const INIT_STATE = {

  couriersData: [],
  loading: true,
  error: null,

  addItem: null,
  adding: true,
  errorAdd: null,

  editItem: null,
  editing: true,
  errorEdit: null,

  deleteItem: null,
  deleting: true,
  errorDelete: null,

  courierOrders: [],
  loadingCourier: true,
  errorCourier: null,

  addedCourier: null,
  addingAssign: false,
  errorAssign: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_COURIERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_COURIERS_SUCCESS:
      return {
        ...state,
        couriersData: action.payload,
        loading: false,
      };

    case GET_COURIERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_COURIER_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdd: null,
      };

    case ADD_COURIER_SUCCESS:
      return {
        ...state,
        addItem: action.payload,
        adding: false,
      };

    case ADD_COURIER_ERROR:
      return {
        ...state,
        adding: false,
        errorAdd: action.payload,
      };
    
    // Редактирование
    case EDIT_COURIER_REQUEST:
      return {
        ...state,
        editing: true,
        errorEdit: null,
      };

    case EDIT_COURIER_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_COURIER_ERROR:
      return {
        ...state,
        editing: false,
        errorEdit: action.payload,
      };
    
    // Редактирование
    case DELETE_COURIER_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDelete: null,
      };

    case DELETE_COURIER_SUCCESS:
      return {
        ...state,
        deleteItem: action.payload,
        deleting: false,
      };

    case DELETE_COURIER_ERROR:
      return {
        ...state,
        deleting: false,
        errorDelete: action.payload,
      };

    // Получение
    case GET_COURIER_ORDERS_REQUEST:
      return {
        ...state,
        loadingCourier: true,
        errorCourier: null,
      };

    case GET_COURIER_ORDERS_SUCCESS:
      return {
        ...state,
        courierOrders: action.payload,
        loadingCourier: false,
      };

    case GET_COURIER_ORDERS_ERROR:
      return {
        ...state,
        loadingCourier: false,
        errorCourier: action.payload,
      };

    // Добавление
    case ASSIGN_ORDER_COURIER_REQUEST:
      return {
        ...state,
        addingAssign: true,
        errorAssign: null,
      };

    case ASSIGN_ORDER_COURIER_SUCCESS:
      return {
        ...state,
        addedCourier: action.payload,
        addingAssign: false,
      };

    case ASSIGN_ORDER_COURIER_ERROR:
      return {
        ...state,
        addingAssign: false,
        errorAssign: action.payload,
      };

    default:
      return state;
  }
};

import {
  SET_ORDER_CUTLERY_REQUEST,
  SET_ORDER_CUTLERY_SUCCESS,
  SET_ORDER_CUTLERY_ERROR,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_ERROR,
  ADD_ORDER_PRODUCT_COUNT_REQUEST,
  ADD_ORDER_PRODUCT_COUNT_SUCCESS,
  ADD_ORDER_PRODUCT_COUNT_ERROR,
  SET_ORDER_PRODUCT_COUNT_REQUEST,
  SET_ORDER_PRODUCT_COUNT_SUCCESS,
  SET_ORDER_PRODUCT_COUNT_ERROR,
  DELETE_ORDER_PRODUCT_REQUEST,
  DELETE_ORDER_PRODUCT_SUCCESS,
  DELETE_ORDER_PRODUCT_ERROR,
} from '../actions';

const INIT_STATE = {
  culteryCount: 0,
  loadingCultery: false,
  errorCultery: null,

  orderAmount: 0,
  loading: false,
  error: null,

  data: null,
  adding: false,
  errorAdd: null,

  editing: false,
  errorEdit: null,

  deleting: false,
  errorDelet: null,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // добавление
    case SET_ORDER_CUTLERY_REQUEST:
      return {
        ...state,
        loadingCultery: true,
        errorCultery: null,
      };

    case SET_ORDER_CUTLERY_SUCCESS:
      return {
        ...state,
        culteryCount: action.payload,
        loadingCultery: false,
      };

    case SET_ORDER_CUTLERY_ERROR:
      return {
        ...state,
        loadingCultery: false,
        errorCultery: action.payload,
      };

    // изменение суммы заказа
    case EDIT_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        orderAmount: action.payload,
        loading: false,
      };

    case EDIT_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // добавление
    case ADD_ORDER_PRODUCT_COUNT_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdd: null,
      };

    case ADD_ORDER_PRODUCT_COUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        adding: false,
      };

    case ADD_ORDER_PRODUCT_COUNT_ERROR:
      return {
        ...state,
        adding: false,
        errorAdd: action.payload,
      };

    // изменение
    case SET_ORDER_PRODUCT_COUNT_REQUEST:
      return {
        ...state,
        editing: true,
        errorEdit: null,
      };

    case SET_ORDER_PRODUCT_COUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        editing: false,
      };

    case SET_ORDER_PRODUCT_COUNT_ERROR:
      return {
        ...state,
        editing: false,
        errorEdit: action.payload,
      };

    // удаление
    case DELETE_ORDER_PRODUCT_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDelet: null,
      };

    case DELETE_ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        deleting: false,
      };

    case DELETE_ORDER_PRODUCT_ERROR:
      return {
        ...state,
        deleting: false,
        errorDelet: action.payload,
      };

    default:
      return state;
  }
};

import { combineReducers } from 'redux';

import user from './user/reducer';
import home from './home/reducer';
import order from './order/reducer';
import orders from './orders/reducer';
import orderData from './orderData/reducer';
import courier from './courier/reducer';
import couriers from './couriers/reducer';
import reviews from './reviews/reducer';
import markets from './markets/reducer';
import marketsReference from './marketsReference/reducer';
import categoryTags from './categoryTags/reducer';
import brands from './brands/reducer';
import partners from './partners/reducer';
import partnerTags from './partnerTags/reducer';
import productCategory from './productCategory/reducer';
import partnerProducts from './partnerProducts/reducer';
import adminPartnerProducts from './adminPartnerProducts/reducer';
import users from './users/reducer';
import customers from './customers/reducer';
import reports from './reports/reducer';
import banners from './banners/reducer';
import promotions from './promotions/reducer';
import deliveryTypes from './deliveryTypes/reducer';


const reducers = combineReducers({
  user,
  home,
  order,
  orders,
  orderData,
  courier,
  couriers,
  reviews,
  markets,
  marketsReference,
  categoryTags,
  brands,
  partners,
  partnerTags,
  productCategory,
  partnerProducts,
  adminPartnerProducts,
  users,
  customers,
  reports,
  banners,
  promotions,
  deliveryTypes,
});

export default reducers;


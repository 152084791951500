import React from 'react';

import { Helmet } from 'react-helmet';
import { Layout } from "antd";

import PropTypes from 'prop-types';

import AppHeader from '../AppHeader';
import AppSidebar from '../AppSidebar';

import { UserDataContext } from 'pages';
import useGetOrdersCount from 'hooks/useGetOrdersCount';
import useConectionWebSockets from 'hooks/useConectionWebSockets';
import useWindowResize from 'hooks/useWindowResize';

import './AppLayout.scss';

export const OrderContext = React.createContext();
export const WindowContext = React.createContext();

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const { token, partnerId, userId, userRole } = React.useContext(UserDataContext);

  const {connection, updateOrders, newOrder, isUpdateOrdersCount} = useConectionWebSockets({
    token, 
    partnerId,
    userId,
    userRole,
  });

  const {ordersCount} = useGetOrdersCount({
    partnerId,
    isUpdateOrdersCount
  });

  const {isMobile, isTablet} = useWindowResize();

  const [collapsed, setCollapsed] = React.useState(false);
  const toggleSidebar = () => setCollapsed((prevState) => !prevState);

  let helmetTitle = ordersCount ? `+${ordersCount} ${ordersCount === 1 ? 'новый заказ' : 'новых заказа'} | CronMarket - Admin panel` : "CronMarket - Admin panel";

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <OrderContext.Provider value={{ordersCount, updateOrders, newOrder}}>
        <WindowContext.Provider value={{isMobile, isTablet}}>
          <Layout className="app">
            <AppHeader toggleSidebar={toggleSidebar} collapsed={collapsed}/>
            <Layout className="app-content">
              <AppSidebar 
                connection={connection} 
                toggleSidebar={toggleSidebar}
                collapsed={collapsed}  
              />
              <Layout className="layout-content">
                <Content
                  className="site-layout-background"
                >
                  {children}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </WindowContext.Provider>
      </OrderContext.Provider>
    </>
  )
};

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppLayout;

// const pulsedTitleIcon = () => {
//   let count = 0;
//   let bool = false
//   const titleIcon = document.getElementById('favicon');
//   setInterval(function(){
//     titleIcon.href = count < 5 ? !bool ? 'user-icon.png' : 'favicon.ico' : 'favicon.ico';
//     bool = !bool;
//     count = count + 1;
//   },700);
// }
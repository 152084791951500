import {
  GET_ADMIN_PARTNER_PRODUCTS_REQUEST,
  GET_ADMIN_PARTNER_PRODUCTS_SUCCESS,
  GET_ADMIN_PARTNER_PRODUCTS_ERROR,
} from '../actions';

const INIT_STATE = {

  productsData: [],
  loading: true,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_ADMIN_PARTNER_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ADMIN_PARTNER_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsData: action.payload,
        loading: false,
      };

    case GET_ADMIN_PARTNER_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import {
  HomeFilled,
  ScheduleFilled,
  ContactsFilled,
  LikeFilled,
  ThunderboltFilled,
  FileExcelFilled,
  UserOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  SwitcherFilled 
} from '@ant-design/icons';

const mainMenuItems = [
  {
    label: "Заказы",
    link: '/partnerOrders',
    icon: <ShoppingCartOutlined className="menu-icon" />,
    levelAccsess: ['Partner'],
  },
  {
    label: "Товары",
    link: '/partner',
    icon: <ScheduleFilled className="menu-icon" />,
    levelAccsess: ['Partner'],
  },
  {
    label: "Главная",
    link: '/home',
    icon: <HomeFilled className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator'],
  },
  {
    label: "Заказы",
    link: '/orders',
    icon: <ShoppingCartOutlined  className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator', 'Moderator'],
  },
  {
    label: "Курьеры",
    link: '/couriers',
    icon: <TeamOutlined  className="menu-icon"/>,
    levelAccsess: ['SuperAdministrator', 'Administrator', 'Moderator'],
  },
  {
    label: "Бренды",
    link: '/brands',
    icon: <SwitcherFilled   className="menu-icon"/>,
    levelAccsess: ['SuperAdministrator', 'Administrator', 'Moderator'],
  },
  {
    label: "Отзывы",
    link: '/reviews',
    icon: <LikeFilled className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator'],
  },
  {
    label: "Акции",
    link: '/promotions',
    parentPath: 'promotions',
    icon: <ThunderboltFilled className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator'],
    childs: [
      {
        label: "Баннеры",
        link: '/promotions/banners',
        levelAccsess: ['SuperAdministrator', 'Administrator'],
      },
      {
        label: "Промокоды",
        link: '/promotions/promo',
        levelAccsess: ['SuperAdministrator', 'Administrator'],
      },
    ]
  },
  {
    label: "Отчеты",
    link: '/reports',
    icon: <FileExcelFilled className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator', 'Partner'],
    childs: [
      {
        label: "Отчет по заказам",
        link: '/reports/orders',
        levelAccsess: ['SuperAdministrator', 'Administrator', 'Partner'],
      },
      {
        label: "Отчет по клиентам",
        link: '/reports/clients',
        levelAccsess: ['SuperAdministrator', 'Administrator'],
      },
      {
        label: "Отчет по метрикам",
        link: '/reports/productMetrics',
        levelAccsess: ['SuperAdministrator', 'Administrator'],
      },
    ]
  },
  {
    label: "Клиенты",
    link: '/customers',
    icon: <UserOutlined className="menu-icon" />,
    levelAccsess: ['SuperAdministrator', 'Administrator'],
  },
  {
    label: "Пользователи",
    link: '/users',
    icon: <ContactsFilled className="menu-icon" />,
    levelAccsess: ['SuperAdministrator'],
  },
  {
    label: "Мои заказы",
    link: '/myorders',
    icon: <ShoppingCartOutlined  className="menu-icon" />,
    levelAccsess: ['Courier'],
  },
  // {
  //   label: "Архив заказов",
  //   link: '/allorders',
  //   icon: <ShoppingCartOutlined  className="menu-icon" />,
  //   levelAccsess: ['Courier'],
  // },
  // {
  //   label: "Личные данные",
  //   link: '/profile',
  //   icon: <ShoppingCartOutlined  className="menu-icon" />,
  //   levelAccsess: ['Courier'],
  // },
];

export default mainMenuItems
const convertFio = (fio) => {
  const array = fio.split(' ');

  switch (array.length) {
    case 3:
      return `${array[0]} ${array[1]}`;

    case 2:
      return `${array[0]} ${array[1]}`;

    default:
      return array;
  }
};

export default convertFio;
import React from 'react';
import { Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';

import './CustomSidebarToggler.scss';

const CustomSidebarToggler = ({ toggleSidebar, collapsed }) =>  (
  <Button
    className="btn-toggler trigger"
    onClick={toggleSidebar}
    icon={collapsed ? <MenuUnfoldOutlined style={{fontSize: '20px'}}/> : <MenuFoldOutlined style={{fontSize: '20px'}}/>}
  />
);

CustomSidebarToggler.defaultProps = {
  collapsed: false,
  toggleSidebar: undefined,
};

export default CustomSidebarToggler;

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';

import { getAccessToken } from 'services/authService';

const LevelAccsessRoute = React.memo(({ children: component, path, levelAccess, role, exact }) => {
  const token = getAccessToken();
  const isAccessUser = (role.length && role !== null && role !== undefined) && levelAccess.includes(role);
  
  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        token && isAccessUser ? (
          component
        ) : (
          <NotFoundPage />
        )
      )}
    />
  );
});

LevelAccsessRoute.defaultProps = {
  exact: false,
  levelAccess: [],
  role: '',
};

LevelAccsessRoute.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
  levelAccess: PropTypes.array,
  role: PropTypes.string,
  exact: PropTypes.bool,
};

export default LevelAccsessRoute;

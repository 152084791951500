import axios from 'axios';
import { getAccessToken } from './authService';
import getErrorText from '../utils/getErrorText';

// Запросы с токеном
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  async (request) => {
    request.headers.Authorization = `Bearer ${getAccessToken()}`;
    return request;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(new Error(getErrorText(error)))
);

// Запросы без токена
export default instance;

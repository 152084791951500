import formatISO from 'date-fns/formatISO';

const isDateField = (field) => field instanceof Date;
const isFileField = (field) => field instanceof File;
const isArray = (field) => field instanceof Array;
const isObject = (field) => field !== null && !isArray(field) && typeof field === 'object';
const isSelectField = (field) => Object.prototype.hasOwnProperty.call(field, 'label');

const objectToFormData = (values, formData = new FormData(), namespace) => {
  Object.entries(values).forEach(([key, value]) => {
    const formKey = namespace ? `${namespace}[${key}]` : key;
    // if (value === null) formData.set(formKey, null);
    if (value || value === 0 || typeof value === 'boolean') { 
      if (isDateField(value)) {
        formData.set(formKey, formatISO(value));
      } else if (isObject(value)) {
        if (isSelectField(value)) {
          formData.set(formKey, value.value);
        } else if (isFileField(value)) {
          formData.set(formKey, value);
        } else {
          objectToFormData(value, formData, formKey);
        }
      } else if (isArray(value)) {
        objectToFormData(value, formData, formKey); 
      } else {
        formData.set(formKey, value);
      }
    }
  });
  return formData;
};

export default objectToFormData;

import {
  GET_PARTNER_PRODUCTS_REQUEST,
  GET_PARTNER_PRODUCTS_SUCCESS,
  GET_PARTNER_PRODUCTS_ERROR,
  ADD_PARTNER_PRODUCT_REQUEST,
  ADD_PARTNER_PRODUCT_SUCCESS,
  ADD_PARTNER_PRODUCT_ERROR,
  EDIT_PARTNER_PRODUCT_REQUEST,
  EDIT_PARTNER_PRODUCT_SUCCESS,
  EDIT_PARTNER_PRODUCT_ERROR,
  DELETE_PARTNER_PRODUCT_REQUEST,
  DELETE_PARTNER_PRODUCT_SUCCESS,
  DELETE_PARTNER_PRODUCT_ERROR,
  SAVE_PARTNERS,
} from "../actions";

const INIT_STATE = {
  productsData: [],
  productsTotalCount: null,
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,

  partnersByBrand: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Получение
    case GET_PARTNER_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PARTNER_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsData: action.payload?.data,
        productsTotalCount: action.payload?.totalCount,
        loading: false,
      };

    case GET_PARTNER_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_PARTNER_PRODUCT_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_PARTNER_PRODUCT_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case ADD_PARTNER_PRODUCT_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_PARTNER_PRODUCT_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_PARTNER_PRODUCT_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_PARTNER_PRODUCT_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_PARTNER_PRODUCT_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_PARTNER_PRODUCT_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_PARTNER_PRODUCT_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    //Сохранение партнеров
    case SAVE_PARTNERS:
      return {
        ...state,
        partnersByBrand: action.payload,
      };

    default:
      return state;
  }
};

import {
  GET_PARTNER_DELIVERY_TYPES_REQUEST,
  GET_PARTNER_DELIVERY_TYPES_SUCCESS,
  GET_PARTNER_DELIVERY_TYPES_ERROR,
  ADD_PARTNER_DELIVERY_TYPE_REQUEST,
  ADD_PARTNER_DELIVERY_TYPE_SUCCESS,
  ADD_PARTNER_DELIVERY_TYPE_ERROR,
} from '../actions';

const INIT_STATE = {

  typesData: [],
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_PARTNER_DELIVERY_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PARTNER_DELIVERY_TYPES_SUCCESS:
      return {
        ...state,
        typesData: action.payload,
        loading: false,
      };

    case GET_PARTNER_DELIVERY_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_PARTNER_DELIVERY_TYPE_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_PARTNER_DELIVERY_TYPE_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case ADD_PARTNER_DELIVERY_TYPE_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    default:
      return state;
  }
};

import React from 'react';
import { Form, Checkbox, Input, Button, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";

import logo from '../../images/logo.svg';
import cronLogo from '../../images/CronMarket_logo_notext_color_256.png';

import './Login.scss';

const logoArr = [1,2,3];

const LoginForm = React.memo(({
  onFinish, 
  loginHandler,
  passwordHandler,
  checkHandler,
  onIconRender,
  loading,
  error,
  login,
  password,
  checked,
}) => (
  <div className="login-page-container">
    {logoArr.map((it) => (
      <div key={it} className="logo-square">
        <img className="logo" src={cronLogo} />
      </div>
    ))}
    <div className="login-content">
      <Form 
        name="normal_login"
        layout="vertical"
        className="form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="title-block">
          <img className="logo" src={logo} alt="Логотип" />
          <h2 className="title-block-text">Вход в административную панель</h2>
        </div>

        <div className="content-block">
          <Form.Item
            className="login-form-group" 
            name="login"
            validateStatus={error?.login ? 'error' : undefined}
            rules={[
              {
                required: true,
                message: "Пожалуйста введите ваш логин!"
              },
              {
                min: 4,
                message: "Логин должен содержать не меньше 4-х символов!"
              }
            ]}
          >
            <Input
              prefix={<UserOutlined className="login-form-icon" />}
              value={login}
              onChange={loginHandler}
              placeholder="Логин"
              allowClear
              maxLength="20"
            />
          </Form.Item>

          <Form.Item
            className="login-form-group"
            style={{marginBottom: '25px'}} 
            name="password"
            validateStatus={error?.password ? 'error' : undefined}
            rules={[
              {
                required: true,
                message: "Пожалуйста введите ваш пароль!"
              },
              {
                min: 4,
                message: "Пароль должен содержать не меньше 4-х символов!"
              }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="login-form-icon" />}
              value={password}
              onChange={passwordHandler}
              iconRender={onIconRender}
              placeholder="Пароль"
              allowClear
              maxLength="30"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox 
              className="remember-checkbox"
              checked={checked}
              onChange={checkHandler}
            >
              Запомнить меня
            </Checkbox>
          </Form.Item>

          <Button htmlType="submit" className="btn-login" block disabled={(password.length <= 3 || login.length <= 3)}>
            {loading ? (
              <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: '#fff'}} spin/>} />
            ) : (
              'Войти'
            )}
          </Button>
        </div>
      </Form>
    </div>
    <div className="footer">
      <p className="copyright">CronMarket ©2021 Все права зашищены ООО «Крон»</p>
    </div>
  </div>
));

export default LoginForm;

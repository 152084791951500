import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Menu } from "antd";

import './AppSidebar.scss';

const NestedMenu = React.memo(({navItems, userRole}) => {
  const location = useLocation();
  const path = location?.pathname;
  const [curentMenuItem, setCurentMenuItem] = React.useState('');

  //кастыль для того чтобы во вложенном меню всегда отображался "selectedKeys"
  //в случае когда при переходе на эту страницу есть еще вложенное меню уже в самой странице
  React.useEffect(() => { 
    if (path.length) {
      const pathSplit = path.split('/');
      const pathRoot = pathSplit.length > 3 ? pathSplit.slice(0, 3).join('/') : pathSplit.join('/');
      pathRoot.length && setCurentMenuItem(pathRoot)
    }
  }, [path])

  if (!navItems && curentMenuItem.length) {
    return null
  }

  return (
    <Menu
      className="tablet-nested-menu"
      mode="inline"
      selectedKeys={[`${path}`]}
      defaultSelectedKeys={[`${path}`]}
    >
      {navItems && navItems.map(({ label, link, levelAccsess }) => (
        levelAccsess.includes(userRole) ? (
          <Menu.Item key={link} className={`nested-nav-items ${curentMenuItem === link ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to={link}>
              {label}
            </NavLink>
          </Menu.Item>
        ) : null)
      )}
    </Menu>
  )
});

export default NestedMenu;
import {lazy} from 'react';

const HomePage = lazy(() => import('pages/home-page'));
const OrdersPage = lazy(() => import('pages/orders-page'));
const СouriersPage = lazy(() => import('pages/couriers-page'));
const ReviewsPage = lazy(() => import('pages/reviews-page'));
const MarketsPage = lazy(() => import('pages/markets-page'));
const PartnersPage = lazy(() => import('pages/partners-page'));
const ProductsPage = lazy(() => import('pages/products-page'));
const UsersPage = lazy(() => import('pages/users-page'));
const CustomersPage = lazy(() => import('pages/customers-page'));
const CustomerCardPage = lazy(() => import('pages/customer-card-page'));
const ReportsPage = lazy(() => import('pages/reports'));
const PromotionsPage = lazy(() => import('pages/promotions'));
const PartnerProducts = lazy(() => import('pages/partner-products-page'));
const CourierOrdersPage = lazy(() => import('pages/courier-orders-page'));
const BrandPartnerPage = lazy(() => import('pages/brand-partner-page'));
const BrandsPage = lazy(() => import('pages/brands-page'));
const BrandPage = lazy(() => import('pages/brand-page'));
// const CourierArchiveOrdersPage = lazy(() => import('pages/courier-archive-orders-page'));
// const CourierProfilePage = lazy(() => import('pages/courier-profile-page'));
const CustomVirtualTablePage = lazy(() => import('pages/custom-virtual-table'));
const NotFoundPage = lazy(() => import('pages/not-found-page'));


const routes = [
  {
    path: "/home/cities/:cityName=:cityId/markets/:categoryName=:categoryId/partners/:partnerName=:partnerId",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <ProductsPage/>,
  },
  {
    path: "/home/cities/:cityName=:cityId/markets/:categoryName=:categoryId",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <PartnersPage/>,
  },
  {
    path: "/home/cities/:cityName=:cityId/markets",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <MarketsPage/>,
  },
  {
    path: "/home",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <HomePage/>,
  },
  {
    path: "/users",
    levelAccess: ['SuperAdministrator'],
    page: <UsersPage/>,
  },
  {
    path: "/customers",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <CustomersPage/>,
  },
  {
    path: "/customerCard/:customerName=:customerId",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <CustomerCardPage/>,
  },
  {
    path: "/orders",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <OrdersPage/>,
  },
  {
    path: "/couriers",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <СouriersPage/>,
  },
  {
    path: "/brands/:brandName=:brandId/:partnerName=:partnerId",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <BrandPartnerPage/>,
  },
  {
    path: "/brands/:brandName=:brandId",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <BrandPage/>,
  },
  {
    path: "/brands",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator'],
    page: <BrandsPage/>,
  },
  {
    path: "/reviews",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <ReviewsPage/>,
  },
  {
    path: "/reports",
    levelAccess: ['SuperAdministrator', 'Administrator', 'Partner'],
    page: <ReportsPage/>,
    exact: true
  },
  {
    path: "/promotions",
    levelAccess: ['SuperAdministrator', 'Administrator'],
    page: <PromotionsPage/>,
    exact: true
  },
  {
    path: "/partnerOrders",
    levelAccess: ['Partner'],
    page: <OrdersPage/>,
  },
  {
    path: "/partner",
    levelAccess: ['Partner'],
    page: <PartnerProducts/>,
  },
  {
    path: "/customTable",
    levelAccess: ['SuperAdministrator', 'Administrator', ],
    page: <CustomVirtualTablePage/>,
  },
  {
    path: '/myorders',
    levelAccess: ['Courier'],
    page: <CourierOrdersPage/>,
  },
  // {
  //   path: '/allorders',
  //   levelAccsess: ['Courier'],
  //   page: <CourierArchiveOrdersPage/>,
  // },
  // {
  //   path: '/profile',
  //   levelAccsess: ['Courier'],
  //   page: <CourierProfilePage/>,
  // },
  {
    path: '',
    levelAccess: ['SuperAdministrator', 'Administrator', 'Moderator', 'Partner'],
    page: <NotFoundPage/>,
  },
];

export default routes;
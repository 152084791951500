import React from 'react';
import { Result } from 'antd';

const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="К сожалению, посещенная вами страница не существует."
  />
);

export default NotFoundPage;

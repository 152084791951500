import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../images/CronMarket_logo_notext_color_256.png';

import './TopBarLogoRender.scss';

const TopBarLogoRender = ({ redirectPath, isShortTitle }) =>  (
  <NavLink className="logo-link" to={redirectPath}>
    <img className="logo-link-icon" src={logo} alt="Логотип" /> 
    <span className="logo-link-title">{isShortTitle ? "CronMarket" : "CronMarket - Admin panel"}</span>
  </NavLink>
);

TopBarLogoRender.defaultProps = {
  isShortTitle: false,
};

export default TopBarLogoRender;

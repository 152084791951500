import axios from '../../services/apiService';

import {
  SET_CLEAR_FILTERED_ORDERS_SUCCESS,
  GET_FILTERED_ORDERS_REQUEST,
  GET_FILTERED_ORDERS_SUCCESS,
  GET_FILTERED_ORDERS_ERROR,
  SET_NEW_ORDERS_SUCCESS,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_MOD_ORDERS_COUNT_REQUEST,
  GET_MOD_ORDERS_COUNT_SUCCESS,
  GET_MOD_ORDERS_COUNT_ERROR,
  SET_ORDER_STATUS_REQUEST,
  SET_ORDER_STATUS_SUCCESS,
  SET_ORDER_STATUS_ERROR,
  CONFIRM_ORDER_REQUEST,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_ORDER_ERROR,
} from '../actions';

// Обновить список всех заказов
const setClearFilteredOrders = () => {
  return ({
    type: SET_CLEAR_FILTERED_ORDERS_SUCCESS,
    payload: [],
  })
};


// Получить все непринятые заказы
const getFilteredOrdersRequested = () => ({
  type: GET_FILTERED_ORDERS_REQUEST,
});

const getFilteredOrdersSuccess = (responseData) => ({
  type: GET_FILTERED_ORDERS_SUCCESS,
  payload: responseData,
});

const getFilteredOrdersError = (error) => ({
  type: GET_FILTERED_ORDERS_ERROR,
  payload: error,
});

const getFilteredOrdersRequest = async (values) => {
  const params = {
    fromDate: values.fromDate ? values.fromDate : null,
    toDate: values.toDate ? values.toDate : null,
    cityId: values?.сityId ? values?.сityId : null,
    isToday: values.isToday ? values.isToday : null,
    isActive: values.isActive ? values.isActive : null,
    statusId: values?.statusId ? values?.statusId : null,
    partnerId: values?.partnerId ? values?.partnerId : null,
    PageSize: values.pageSize ? values.pageSize : null,
    Page: values.page !== undefined || values.page !== null ? values.page : null,
    SearchText: values?.searchText ? values?.searchText : null,
    OrderByParams: values?.orderByParams
  }
  return axios.get(`Order/GetOrders`, { params }).then((response) => response.data);
};

const getFilteredOrders = (values) => (dispatch) => {
  dispatch(getFilteredOrdersRequested());
  return getFilteredOrdersRequest(values)
    .then((data) => dispatch(getFilteredOrdersSuccess(data)))
    .catch((error) => dispatch(getFilteredOrdersError(error)));
};

// Обновить список всех заказов
const setNewOrders = (responseData) => {
  return ({
    type: SET_NEW_ORDERS_SUCCESS,
    payload: responseData,
  })
};

// Получить все заказы
const getOrdersRequested = () => ({
  type: GET_ORDERS_REQUEST,
});

const getOrdersSuccess = (responseData) => {
  return ({
    type: GET_ORDERS_SUCCESS,
    payload: responseData,
  })
};

const getOrdersError = (error) => ({
  type: GET_ORDERS_ERROR,
  payload: error,
});

const getOrdersRequest = async (values) => {
  const params = {
    fromDate: values.fromDate ? values.fromDate : null,
    toDate: values.toDate ? values.toDate : null,
    cityId: values?.сityId ? values?.сityId : null,
    isToday: values.isToday ? values.isToday : null,
    isActive: values.isActive ? values.isActive : null,
    statusId: values?.statusId ? values?.statusId : null,
    partnerId: values?.partnerId ? values?.partnerId : null,
    PageSize: values.pageSize ? values.pageSize : null,
    Page: values.page !== undefined || values.page !== null ? values.page : null,
    SearchText: values?.searchText ? values?.searchText : null
  }
  return axios.get(`Order/GetOrders`, { params }).then((response) => response.data);
};

const getOrders = (values) => (dispatch) => {
  dispatch(getOrdersRequested());
  return getOrdersRequest(values)
    .then((data) => dispatch(getOrdersSuccess(data)))
    .catch((error) => dispatch(getOrdersError(error)));
};

// Получить кол-во заказов в статусе модерации
const getModOrdersCountRequested = () => ({
  type: GET_MOD_ORDERS_COUNT_REQUEST,
});

const getModOrdersCountSuccess = (responseData) => ({
  type: GET_MOD_ORDERS_COUNT_SUCCESS,
  payload: responseData,
});

const getModOrdersCountError = (error) => ({
  type: GET_MOD_ORDERS_COUNT_ERROR,
  payload: error,
});

const getModOrdersCountRequest = async (values) => {
  const params = { ...values };
  return axios.get(`Order/GetModerationOrdersCount`, { params }).then((response) => response.data);
};

const getModOrdersCount = (values) => (dispatch) => {
  dispatch(getModOrdersCountRequested());
  return getModOrdersCountRequest(values)
    .then((data) => dispatch(getModOrdersCountSuccess(data)))
    .catch((error) => dispatch(getModOrdersCountError(error)));
};

// Измененить статус заказа
const setOrderStatusRequested = () => ({
  type: SET_ORDER_STATUS_REQUEST,
});

const setOrderStatusSuccess = (data) => ({
  type: SET_ORDER_STATUS_SUCCESS,
  payload: data,
});

const setOrderStatusError = (error) => ({
  type: SET_ORDER_STATUS_ERROR,
  payload: error,
});

const setOrderStatusRequest = async (values) => {
  return axios.put(`Order/SetOrderStatus`, values).then((response) => response.data);
};

const setOrderStatus = (values) => (dispatch) => {
  dispatch(setOrderStatusRequested());
  return setOrderStatusRequest(values)
    .then((values) => {
      dispatch(setOrderStatusSuccess(values));
      return 'Статус заказа изменен !';
    })
    .catch((error) => {
      dispatch(setOrderStatusError(error));
      throw error;
    });
};

// Принять заказ
const confirmOrderRequested = () => ({
  type: CONFIRM_ORDER_REQUEST,
});

const confirmOrderSuccess = (data) => ({
  type: CONFIRM_ORDER_SUCCESS,
  payload: data,
});

const confirmOrderError = (error) => ({
  type: CONFIRM_ORDER_ERROR,
  payload: error,
});

const confirmOrderRequest = async (values) => {
  return axios.put(`Order/ConfirmOrder`, values).then((response) => response.data);
};

const confirmOrder = (values) => (dispatch) => {
  dispatch(confirmOrderRequested());
  return confirmOrderRequest(values)
    .then((values) => {
      dispatch(confirmOrderSuccess(values));
      return 'Заказа принят !';
    })
    .catch((error) => {
      dispatch(confirmOrderError(error));
      throw error;
    });
};

export { setClearFilteredOrders, setNewOrders, getFilteredOrders, getOrders, getModOrdersCount, setOrderStatus, confirmOrder }; //getPastOrdersCount, getOrderStatuses,

import {
  SET_NEW_COURIER_ORDERS_SUCCESS,
  SET_COURIER_FB_TOKEN_REQUEST,
  SET_COURIER_FB_TOKEN_SUCCESS,
  SET_COURIER_FB_TOKEN_ERROR,
  GET_COURIER_DATA_REQUEST,
  GET_COURIER_DATA_SUCCESS,
  GET_COURIER_DATA_ERROR,
  GET_COURIER_ACTIVE_ORDERS_REQUEST,
  GET_COURIER_ACTIVE_ORDERS_SUCCESS,
  GET_COURIER_ACTIVE_ORDERS_ERROR,
  GET_COURIER_ARCHIVE_ORDERS_REQUEST,
  GET_COURIER_ARCHIVE_ORDERS_SUCCESS,
  GET_COURIER_ARCHIVE_ORDERS_ERROR,
  GET_COURIER_STATUSES_REQUEST,
  GET_COURIER_STATUSES_SUCCESS,
  GET_COURIER_STATUSES_ERROR,
  EDIT_COURIER_ORDER_STATUS_REQUEST,
  EDIT_COURIER_ORDER_STATUS_SUCCESS,
  EDIT_COURIER_ORDER_STATUS_ERROR,
  SET_COURIER_STATUS_REQUEST,
  SET_COURIER_STATUS_SUCCESS,
  SET_COURIER_STATUS_ERROR,
} from '../actions';

const INIT_STATE = {

  courierToken: null,
  loadingToken: true,
  errorToken: null,

  courierInfo: null,
  loadingInfo: true,
  errorInfo: null,

  courierActiveOrders: [],
  loading: true,
  error: null,

  courierStatuses: [],
  loadingStatuses: true,
  errorStatuses: null,

  editItem: null,
  editing: true,
  errorEdit: null,

  editStatus: null,
  editingStatus: true,
  errorEditStatus: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Обновление
    case SET_NEW_COURIER_ORDERS_SUCCESS:
      return {
        ...state,
        courierActiveOrders: action.payload,
        loading: false,
      };

      // Добавление
      case SET_COURIER_FB_TOKEN_REQUEST:
        return {
          ...state,
          loadingToken: true,
          errorToken: null,
        };
  
      case SET_COURIER_FB_TOKEN_SUCCESS:
        return {
          ...state,
          courierToken: action.payload,
          loadingToken: false,
        };
  
      case SET_COURIER_FB_TOKEN_ERROR:
        return {
          ...state,
          loadingToken: false,
          errorToken: action.payload,
        };  

    // Получение
    case GET_COURIER_DATA_REQUEST:
      return {
        ...state,
        loadingInfo: true,
        errorInfo: null,
      };

    case GET_COURIER_DATA_SUCCESS:
      return {
        ...state,
        courierInfo: action.payload,
        loadingInfo: false,
      };

    case GET_COURIER_DATA_ERROR:
      return {
        ...state,
        loadingInfo: false,
        errorInfo: action.payload,
      };

    // Получение
    case GET_COURIER_ACTIVE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_COURIER_ACTIVE_ORDERS_SUCCESS:
      return {
        ...state,
        courierActiveOrders: action.payload,
        loading: false,
      };

    case GET_COURIER_ACTIVE_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      // Получение
      case GET_COURIER_STATUSES_REQUEST:
        return {
          ...state,
          loadingStatuses: true,
          errorStatuses: null,
        };
  
      case GET_COURIER_STATUSES_SUCCESS:
        return {
          ...state,
          courierStatuses: action.payload,
          loadingStatuses: false,
        };
  
      case GET_COURIER_STATUSES_ERROR:
        return {
          ...state,
          loadingStatuses: false,
          errorStatuses: action.payload,
        };

    // Редактирование
    case EDIT_COURIER_ORDER_STATUS_REQUEST:
      return {
        ...state,
        editing: true,
        errorEdit: null,
      };

    case EDIT_COURIER_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_COURIER_ORDER_STATUS_ERROR:
      return {
        ...state,
        editing: false,
        errorEdit: action.payload,
      };

      // Редактирование
      case SET_COURIER_STATUS_REQUEST:
        return {
          ...state,
          editingStatus: true,
          errorEditStatus: null,
        };
  
      case SET_COURIER_STATUS_SUCCESS:
        return {
          ...state,
          editStatus: action.payload,
          editingStatus: false,
        };
  
      case SET_COURIER_STATUS_ERROR:
        return {
          ...state,
          editingStatus: false,
          errorEditStatus: action.payload,
        };
    
    default:
      return state;
  }
};

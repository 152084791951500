import { Suspense, createContext } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import LevelAccsessRoute from 'components/generic/LevelAccsessRoute';
import AppLayout from 'components/AppLayout';

import { getAccessToken } from 'services/authService';

import routes from 'utils/routes';
import userRedirectPage from 'utils/userRedirectPage';

export const UserDataContext = createContext();

const AppView = () => {
  const { userData } = useSelector(({ user }) => user);
  const userRole = userData?.role;

  if (!userData && userData === null) return null

  return (
    <UserDataContext.Provider 
      value={{
        token: getAccessToken(),
        userRole,
        userId: userData?.userId,
        partnerUserId: userData?.partnerId,
        isPartner: userRole === "Partner",
        marketId: userData?.marketCategoryId,
        userFIO: userData?.fullName,
      }}
    >
      <AppLayout>
        <Suspense fallback={''}>
          <Switch>
            <Redirect from="/" to={userRedirectPage[userRole]} exact/>
            {routes.map(({path, levelAccess, page}) => (
              <LevelAccsessRoute key={path} path={path} levelAccess={levelAccess} role={userRole}>
                {page}
              </LevelAccsessRoute>
            ))}
          </Switch>
        </Suspense>
      </AppLayout>
    </UserDataContext.Provider>
  )
};

export default AppView;
import {
  GET_CUSTOMERS_REPORT_REQUEST,
  GET_CUSTOMERS_REPORT_SUCCESS,
  GET_CUSTOMERS_REPORT_ERROR,
  GET_ORDERS_REPORT_REQUEST,
  GET_ORDERS_REPORT_SUCCESS,
  GET_ORDERS_REPORT_ERROR,
} from '../actions';

const INIT_STATE = {
  
  customersReportData: [],
  commonAverageSum: 0,
  commonSum: 0,
  customersCount: 0,
  commonOrdersCount: 0,
  customersReportDate: '',
  loadingCustomersR: false,
  errorCustomersR: null,

  ordersReportData: [],
  ordersCount: 0,
  commonSum: 0,
  commonPartnerSum: 0,
  commonAdminSum: 0,
  ordersReportDate: '',
  loadingOrdersR: false,
  errorOrdersR: null,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_CUSTOMERS_REPORT_REQUEST:
      return {
        ...state,
        loadingCustomersR: true,
        errorCustomersR: null,
      };

    case GET_CUSTOMERS_REPORT_SUCCESS:
      return {
        ...state,
        customersReportData: action.payload?.customers,
        commonAverageSum: action.payload?.commonAverageSum,
        commonSum: action.payload?.commonSum,
        customersCount: action.payload?.customersCount,
        commonOrdersCount: action.payload?.commonOrdersCount,
        customersReportDate: action.payload?.reportDate,
        loadingCustomersR: false,
      };

    case GET_CUSTOMERS_REPORT_ERROR:
      return {
        ...state,
        loadingCustomersR: false,
        errorCustomersR: action.payload,
      };


      // Получение
      case GET_ORDERS_REPORT_REQUEST:
        return {
          ...state,
          loadingOrdersR: true,
          errorOrdersR: null,
        };
  
      case GET_ORDERS_REPORT_SUCCESS:
        return {
          ...state,
          ordersCount: action.payload?.ordersCount,
          commonSum: action.payload?.commonSum,
          commonPartnerSum: action.payload?.commonPartnerSum,
          commonAdminSum: action.payload?.commonAdminSum,
          ordersReportData: action.payload?.orders,
          ordersReportDate: action.payload?.reportDate,
          loadingOrdersR: false,
        };
  
      case GET_ORDERS_REPORT_ERROR:
        return {
          ...state,
          loadingOrdersR: false,
          errorOrdersR: action.payload,
        };

   

    default:
      return state;
  }
};

import {
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
  ADD_CITY_REQUEST,
  ADD_CITY_SUCCESS,
  ADD_CITY_ERROR,
  EDIT_CITY_REQUEST,
  EDIT_CITY_SUCCESS,
  EDIT_CITY_ERROR,
  DELETE_CITY_REQUEST,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_ERROR,
} from '../actions';

const INIT_STATE = {
  cityData: [],
  loadingCity: true,
  errorCity: null,

  citiesData: [],
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    // Получение
    case GET_CITY_REQUEST:
      return {
        ...state,
        loadingCity: true,
        errorCity: null,
      };

    case GET_CITY_SUCCESS:
      return {
        ...state,
        cityData: action.payload,
        loadingCity: false,
      };

    case GET_CITY_ERROR:
      return {
        ...state,
        loadingCity: false,
        errorCity: action.payload,
      };

    // Получение
    case GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        citiesData: action.payload,
        loading: false,
      };

    case GET_CITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_CITY_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_CITY_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case ADD_CITY_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_CITY_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_CITY_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_CITY_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_CITY_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_CITY_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_CITY_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

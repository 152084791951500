import {
  SET_CLEAR_FILTERED_ORDERS_SUCCESS,
  GET_FILTERED_ORDERS_REQUEST,
  GET_FILTERED_ORDERS_SUCCESS,
  GET_FILTERED_ORDERS_ERROR,
  SET_NEW_ORDERS_SUCCESS,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_MOD_ORDERS_COUNT_REQUEST,
  GET_MOD_ORDERS_COUNT_SUCCESS,
  GET_MOD_ORDERS_COUNT_ERROR,
  SET_ORDER_STATUS_REQUEST,
  SET_ORDER_STATUS_SUCCESS,
  SET_ORDER_STATUS_ERROR,
} from '../actions';

const INIT_STATE = {

  filteredOrdersData: [],
  loadingFilter: false,
  errorFilter: null,

  ordersData: [],
  loading: false,
  error: null,

  modOrdersCount: [],
  loadingMod: false,
  errorMod: null,


  editItem: null,
  editing: false,
  errorEditing: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case SET_CLEAR_FILTERED_ORDERS_SUCCESS:
      return {
        ...state,
        filteredOrdersData: action.payload,
        loadingFilter: false,
      };
      
    case GET_FILTERED_ORDERS_REQUEST:
      return {
        ...state,
        loadingFilter: true,
        errorFilter: null,
      };

    case GET_FILTERED_ORDERS_SUCCESS:
      return {
        ...state,
        filteredOrdersData: action.payload?.data,
        loadingFilter: false,
      };

    case GET_FILTERED_ORDERS_ERROR:
      return {
        ...state,
        loadingFilter: false,
        errorFilter: action.payload,
      };

    // Обновление
    case SET_NEW_ORDERS_SUCCESS:
      return {
        ...state,
        ordersData: action.payload,
        loading: false,
      };

    case GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        ordersData: action.payload?.data,
        loading: false,
      };

    case GET_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Получение
    // case GET_PAST_ORDERS_COUNT_REQUEST:
    //   return {
    //     ...state,
    //     loadingPastCount: true,
    //     errorPastCount: null,
    //   };

    // case GET_PAST_ORDERS_COUNT_SUCCESS:
    //   return {
    //     ...state,
    //     pastOrdersCount: action.payload,
    //     loadingPastCount: false,
    //   };

    // case GET_PAST_ORDERS_COUNT_ERROR:
    //   return {
    //     ...state,
    //     loadingPastCount: false,
    //     errorPastCount: action.payload,
    //   };      

    // Получение
    case GET_MOD_ORDERS_COUNT_REQUEST:
      return {
        ...state,
        loadingMod: true,
        errorMod: null,
      };

    case GET_MOD_ORDERS_COUNT_SUCCESS:
      return {
        ...state,
        modOrdersCount: action.payload,
        loadingMod: false,
      };

    case GET_MOD_ORDERS_COUNT_ERROR:
      return {
        ...state,
        loadingMod: false,
        errorMod: action.payload,
      };

    // Получение
    // case GET_ORDER_STATUSES_REQUEST:
    //   return {
    //     ...state,
    //     loadingStatus: true,
    //     errorStatus: null,
    //   };

    // case GET_ORDER_STATUSES_SUCCESS:
    //   return {
    //     ...state,
    //     orderStatus: action.payload,
    //     loadingStatus: false,
    //   };

    // case GET_ORDER_STATUSES_ERROR:
    //   return {
    //     ...state,
    //     loadingStatus: false,
    //     errorStatus: action.payload,
    //   };

    // Изменение
    case SET_ORDER_STATUS_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case SET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case SET_ORDER_STATUS_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    default:
      return state;
  }
};

import {
  GET_PRODUCT_CATEGORY_CHILD_REQUEST,
  GET_PRODUCT_CATEGORY_CHILD_SUCCESS,
  GET_PRODUCT_CATEGORY_CHILD_ERROR,
  GET_PRODUCT_CATEGORY_REQUEST,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_ERROR,
  ADD_PRODUCT_CATEGORY_REQUEST,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_ERROR,
  EDIT_PRODUCT_CATEGORY_REQUEST,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_ERROR,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_ERROR,
} from '../actions';

const INIT_STATE = {

  productCategoryTreeData: [],
  loadingTree: true,
  errorTree: null,

  productCategoryData: [],
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

     // Получение
     case GET_PRODUCT_CATEGORY_CHILD_REQUEST:
      return {
        ...state,
        loadingTree: true,
        errorTree: null,
      };

    case GET_PRODUCT_CATEGORY_CHILD_SUCCESS:
      return {
        ...state,
        productCategoryTreeData: action.payload,
        loadingTree: false,
      };

    case GET_PRODUCT_CATEGORY_CHILD_ERROR:
      return {
        ...state,
        loadingTree: false,
        errorTree: action.payload,
      };


    // Получение
    case GET_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategoryData: action.payload,
        loading: false,
      };

    case GET_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case ADD_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_PRODUCT_CATEGORY_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

import {
  GET_BANNER_TYPES_REQUEST,
  GET_BANNER_TYPES_SUCCESS,
  GET_BANNER_TYPES_ERROR,
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_ERROR,
  CREATE_BANNER_REQUEST,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_ERROR,
  EDIT_BANNER_REQUEST,
  EDIT_BANNER_SUCCESS,
  EDIT_BANNER_ERROR,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
} from '../actions';

const INIT_STATE = {
  bannerTypesData: [],
  loadingTypes: true,
  errorTypes: null,

  bannersData: [],
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  // editItem: null,
  // editing: false,
  // errorEditing: null,

  // deletedItem: null,
  // deleting: true,
  // errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_BANNER_TYPES_REQUEST:
      return {
        ...state,
        loadingTypes: true,
        errorTypes: null,
      };

    case GET_BANNER_TYPES_SUCCESS:
      return {
        ...state,
        bannerTypesData: action.payload,
        loadingTypes: false,
      };

    case GET_BANNER_TYPES_ERROR:
      return {
        ...state,
        loadingTypes: false,
        errorTypes: action.payload,
      };

    // Получение
    case GET_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        bannersData: action.payload,
        loading: false,
      };

    case GET_BANNERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case CREATE_BANNER_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case CREATE_BANNER_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case CREATE_BANNER_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_BANNER_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_BANNER_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_BANNER_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_BANNER_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_BANNER_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

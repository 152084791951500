// Авторизация
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

//=====================================================
//_______________________CITIES________________________
//=====================================================
// Получение города
export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

// Получение городов
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

// Добавление города
export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_ERROR = "ADD_CITY_ERROR";

// Редактирование города
export const EDIT_CITY_REQUEST = "EDIT_CITY_REQUEST";
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_CITY_ERROR = "EDIT_CITY_ERROR";

// Удаление города
export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_ERROR = "DELETE_CITY_ERROR";

//=====================================================
//_______________________ORDER_________________________
//=====================================================
// Добавление приборов
export const SET_ORDER_CUTLERY_REQUEST = "SET_ORDER_CUTLERY_REQUEST";
export const SET_ORDER_CUTLERY_SUCCESS = "SET_ORDER_CUTLERY_SUCCESS";
export const SET_ORDER_CUTLERY_ERROR = "SET_ORDER_CUTLERY_ERROR";

// изменение суммы заказа
export const EDIT_ORDER_REQUEST = "EDIT_ORDER_REQUEST";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR";

// Добавление позиции
export const ADD_ORDER_PRODUCT_COUNT_REQUEST = "ADD_ORDER_PRODUCT_COUNT_REQUEST";
export const ADD_ORDER_PRODUCT_COUNT_SUCCESS = "ADD_ORDER_PRODUCT_COUNT_SUCCESS";
export const ADD_ORDER_PRODUCT_COUNT_ERROR = "ADD_ORDER_PRODUCT_COUNT_ERROR";

// Изменение количества у позиции
export const SET_ORDER_PRODUCT_COUNT_REQUEST = "SET_ORDER_PRODUCT_COUNT_REQUEST";
export const SET_ORDER_PRODUCT_COUNT_SUCCESS = "SET_ORDER_PRODUCT_COUNT_SUCCESS";
export const SET_ORDER_PRODUCT_COUNT_ERROR = "SET_ORDER_PRODUCT_COUNT_ERROR";

// Удаление позиции
export const DELETE_ORDER_PRODUCT_REQUEST = "DELETE_ORDER_PRODUCT_REQUEST";
export const DELETE_ORDER_PRODUCT_SUCCESS = "DELETE_ORDER_PRODUCT_SUCCESS";
export const DELETE_ORDER_PRODUCT_ERROR = "DELETE_ORDER_PRODUCT_ERROR";

//=====================================================
//______________________ORDERS________________________
//=====================================================
// заменить заказ на новый в списке заказов
export const SET_NEW_ORDERS_SUCCESS = "SET_NEW_ORDERS_SUCCESS";

// Очистить список отфильтрованных заказов
export const SET_CLEAR_FILTERED_ORDERS_SUCCESS = "SET_CLEAR_FILTERED_ORDERS_SUCCESS";

// Получение заказов
export const GET_FILTERED_ORDERS_REQUEST = "GET_FILTERED_ORDERS_REQUEST";
export const GET_FILTERED_ORDERS_SUCCESS = "GET_FILTERED_ORDERS_SUCCESS";
export const GET_FILTERED_ORDERS_ERROR = "GET_FILTERED_ORDERS_ERROR";

// Получение заказов
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

// Получение заказов по категориям
export const SET_ORDER_DATA_SUCCESS = "SET_ORDER_DATA_SUCCESS";
export const GET_ORDER_DATA_REQUEST = "GET_ORDER_DATA_REQUEST";
export const GET_ORDER_DATA_SUCCESS = "GET_ORDER_DATA_SUCCESS";
export const GET_ORDER_DATA_ERROR = "GET_ORDER_DATA_ERROR";

// Получение непринятое кол-во заказов партнера
// export const GET_PAST_ORDERS_COUNT_REQUEST = 'GET_PAST_ORDERS_COUNT_REQUEST';
// export const GET_PAST_ORDERS_COUNT_SUCCESS = 'GET_PAST_ORDERS_COUNT_SUCCESS';
// export const GET_PAST_ORDERS_COUNT_ERROR = 'GET_PAST_ORDERS_COUNT_ERROR';

// Получение кол-во заказов в статусе модерации
export const GET_MOD_ORDERS_COUNT_REQUEST = "GET_MOD_ORDERS_COUNT_REQUEST";
export const GET_MOD_ORDERS_COUNT_SUCCESS = "GET_MOD_ORDERS_COUNT_SUCCESS";
export const GET_MOD_ORDERS_COUNT_ERROR = "GET_MOD_ORDERS_COUNT_ERROR";

// Получение статусов заказа
// export const GET_ORDER_STATUSES_REQUEST = 'GET_ORDER_STATUSES_REQUEST';
// export const GET_ORDER_STATUSES_SUCCESS = 'GET_ORDER_STATUSES_SUCCESS';
// export const GET_ORDER_STATUSES_ERROR = 'GET_ORDER_STATUSES_ERROR';

// Принять заказ
export const CONFIRM_ORDER_REQUEST = "CONFIRM_ORDER_REQUEST";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_ERROR = "CONFIRM_ORDER_ERROR";

// Редактирование статуса заказа
export const SET_ORDER_STATUS_REQUEST = "SET_ORDER_STATUS_REQUEST";
export const SET_ORDER_STATUS_SUCCESS = "SET_ORDER_STATUS_SUCCESS";
export const SET_ORDER_STATUS_ERROR = "SET_ORDER_STATUS_ERROR";

//=====================================================
//_____________________COURIER_________________________
//=====================================================
// заменить заказ на новый в списке заказов
export const SET_NEW_COURIER_ORDERS_SUCCESS = "SET_NEW_COURIER_ORDERS_SUCCESS";

// Получение информации о курьере
export const SET_COURIER_FB_TOKEN_REQUEST = "SET_COURIER_FB_TOKEN_REQUEST";
export const SET_COURIER_FB_TOKEN_SUCCESS = "SET_COURIER_FB_TOKEN_SUCCESS";
export const SET_COURIER_FB_TOKEN_ERROR = "SET_COURIER_FB_TOKEN_ERROR";

// Получение информации о курьере
export const GET_COURIER_DATA_REQUEST = "GET_COURIER_DATA_REQUEST";
export const GET_COURIER_DATA_SUCCESS = "GET_COURIER_DATA_SUCCESS";
export const GET_COURIER_DATA_ERROR = "GET_COURIER_DATA_ERROR";

// Получение списка активных заказов курьера
export const GET_COURIER_ACTIVE_ORDERS_REQUEST = "GET_COURIER_ACTIVE_ORDERS_REQUEST";
export const GET_COURIER_ACTIVE_ORDERS_SUCCESS = "GET_COURIER_ACTIVE_ORDERS_SUCCESS";
export const GET_COURIER_ACTIVE_ORDERS_ERROR = "GET_COURIER_ACTIVE_ORDERS_ERROR";

// ПОлучение архива заказов курьера
export const GET_COURIER_ARCHIVE_ORDERS_REQUEST = "GET_COURIER_ARCHIVE_ORDERS_REQUEST";
export const GET_COURIER_ARCHIVE_ORDERS_SUCCESS = "GET_COURIER_ARCHIVE_ORDERS_SUCCESS";
export const GET_COURIER_ARCHIVE_ORDERS_ERROR = "GET_COURIER_ARCHIVE_ORDERS_ERROR";

// ПОлучение списка статусов курьера
export const GET_COURIER_STATUSES_REQUEST = "GET_COURIER_STATUSES_REQUEST";
export const GET_COURIER_STATUSES_SUCCESS = "GET_COURIER_STATUSES_SUCCESS";
export const GET_COURIER_STATUSES_ERROR = "GET_COURIER_STATUSES_ERROR";

// Изменение статуса заказа
export const EDIT_COURIER_ORDER_STATUS_REQUEST = "EDIT_COURIER_ORDER_STATUS_REQUEST";
export const EDIT_COURIER_ORDER_STATUS_SUCCESS = "EDIT_COURIER_ORDER_STATUS_SUCCESS";
export const EDIT_COURIER_ORDER_STATUS_ERROR = "EDIT_COURIER_ORDER_STATUS_ERROR";

// Изменение статуса курьера
export const SET_COURIER_STATUS_REQUEST = "SET_COURIER_STATUS_REQUEST";
export const SET_COURIER_STATUS_SUCCESS = "SET_COURIER_STATUS_SUCCESS";
export const SET_COURIER_STATUS_ERROR = "SET_COURIER_STATUS_ERROR";

//=====================================================
//_____________________COURIERS________________________
//=====================================================
// ПОлучение списка курьеров
export const GET_COURIERS_REQUEST = "GET_COURIERS_REQUEST";
export const GET_COURIERS_SUCCESS = "GET_COURIERS_SUCCESS";
export const GET_COURIERS_ERROR = "GET_COURIERS_ERROR";

// Добавление курьера
export const ADD_COURIER_REQUEST = "ADD_COURIER_REQUEST";
export const ADD_COURIER_SUCCESS = "ADD_COURIER_SUCCESS";
export const ADD_COURIER_ERROR = "ADD_COURIER_ERROR";

// Редактирование курьера
export const EDIT_COURIER_REQUEST = "EDIT_COURIER_REQUEST";
export const EDIT_COURIER_SUCCESS = "EDIT_COURIER_SUCCESS";
export const EDIT_COURIER_ERROR = "EDIT_COURIER_ERROR";

// Удаление курьера
export const DELETE_COURIER_REQUEST = "DELETE_COURIER_REQUEST";
export const DELETE_COURIER_SUCCESS = "DELETE_COURIER_SUCCESS";
export const DELETE_COURIER_ERROR = "DELETE_COURIER_ERROR";

// ПОлучение списка заказов курьера
export const GET_COURIER_ORDERS_REQUEST = "GET_COURIER_ORDERS_REQUEST";
export const GET_COURIER_ORDERS_SUCCESS = "GET_COURIER_ORDERS_SUCCESS";
export const GET_COURIER_ORDERS_ERROR = "GET_COURIER_ORDERS_ERROR";

// Закрепление курьера за заказом
export const ASSIGN_ORDER_COURIER_REQUEST = "ASSIGN_ORDER_COURIER_REQUEST";
export const ASSIGN_ORDER_COURIER_SUCCESS = "ASSIGN_ORDER_COURIER_SUCCESS";
export const ASSIGN_ORDER_COURIER_ERROR = "ASSIGN_ORDER_COURIER_ERROR";

// Отвязка курьера от заказа
export const RESET_COURIER_ORDER_REQUEST = "RESET_COURIER_ORDER_REQUEST";
export const RESET_COURIER_ORDER_SUCCESS = "RESET_COURIER_ORDER_SUCCESS";
export const RESET_COURIER_ORDER_ERROR = "RESET_COURIER_ORDER_ERROR";

//=====================================================
//______________________REVIEWS________________________
//=====================================================
// Получение негативных отзывов
export const GET_NEGATIVE_REVIEWS_REQUEST = "GET_NEGATIVE_REVIEWS_REQUEST";
export const GET_NEGATIVE_REVIEWS_SUCCESS = "GET_NEGATIVE_REVIEWS_SUCCESS";
export const GET_NEGATIVE_REVIEWS_ERROR = "GET_NEGATIVE_REVIEWS_ERROR";

// Получение отзывов
export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";

// Редактирование отзыва
export const CONFIRM_REVIEW_REQUEST = "CONFIRM_REVIEW_REQUEST";
export const CONFIRM_REVIEW_SUCCESS = "CONFIRM_REVIEW_SUCCESS";
export const CONFIRM_REVIEW_ERROR = "CONFIRM_REVIEW_ERROR";

// Удаление отзыва
export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_ERROR = "DELETE_REVIEW_ERROR";

//=====================================================
//_______________________MARKETS_______________________
//=====================================================

// Получение категорий рынка
export const GET_MARKETS_REQUEST = "GET_MARKETS_REQUEST";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_ERROR = "GET_MARKETS_ERROR";

// Добавление категории
export const ADD_MARKET_REQUEST = "ADD_MARKET_REQUEST";
export const ADD_MARKET_SUCCESS = "ADD_MARKET_SUCCESS";
export const ADD_MARKET_ERROR = "ADD_MARKET_ERROR";

// Редактирование категории
export const EDIT_MARKET_REQUEST = "EDIT_MARKET_REQUEST";
export const EDIT_MARKET_SUCCESS = "EDIT_MARKET_SUCCESS";
export const EDIT_MARKET_ERROR = "EDIT_MARKET_ERROR";

// Удаление категории
export const DELETE_MARKET_REQUEST = "DELETE_MARKET_REQUEST";
export const DELETE_MARKET_SUCCESS = "DELETE_MARKET_SUCCESS";
export const DELETE_MARKET_ERROR = "DELETE_MARKET_ERROR";

//=====================================================
//__________________MARKETS_REFERENCE__________________
//=====================================================

// Получение категорий рынков
export const GET_MARKET_CATEGORIES_REQUEST = "GET_MARKET_CATEGORIES_REQUEST";
export const GET_MARKET_CATEGORIES_SUCCESS = "GET_MARKET_CATEGORIES_SUCCESS";
export const GET_MARKET_CATEGORIES_ERROR = "GET_MARKET_CATEGORIES_ERROR";

// Добавление категории
export const ADD_MARKET_CATEGORIES_REQUEST = "ADD_MARKET_CATEGORIES_REQUEST";
export const ADD_MARKET_CATEGORIES_SUCCESS = "ADD_MARKET_CATEGORIES_SUCCESS";
export const ADD_MARKET_CATEGORIES_ERROR = "ADD_MARKET_CATEGORIES_ERROR";

// Редактирование категории
export const EDIT_MARKET_CATEGORIES_REQUEST = "EDIT_MARKET_CATEGORIES_REQUEST";
export const EDIT_MARKET_CATEGORIES_SUCCESS = "EDIT_MARKET_CATEGORIES_SUCCESS";
export const EDIT_MARKET_CATEGORIES_ERROR = "EDIT_MARKET_CATEGORIES_ERROR";

// Удаление категории
export const DELETE_MARKET_CATEGORIES_REQUEST = "DELETE_MARKET_CATEGORIES_REQUEST";
export const DELETE_MARKET_CATEGORIES_SUCCESS = "DELETE_MARKET_CATEGORIES_SUCCESS";
export const DELETE_MARKET_CATEGORIES_ERROR = "DELETE_MARKET_CATEGORIES_ERROR";

//=====================================================
//_______________________MARKET_CATEGORY_TAGS__________
//=====================================================

// Получение категорий рынков
export const GET_CATEGORY_TAGS_REQUEST = "GET_CATEGORY_TAGS_REQUEST";
export const GET_CATEGORY_TAGS_SUCCESS = "GET_CATEGORY_TAGS_SUCCESS";
export const GET_CATEGORY_TAGS_ERROR = "GET_CATEGORY_TAGS_ERROR";

// Добавление категории
export const ADD_CATEGORY_TAG_REQUEST = "ADD_CATEGORY_TAG_REQUEST";
export const ADD_CATEGORY_TAG_SUCCESS = "ADD_CATEGORY_TAG_SUCCESS";
export const ADD_CATEGORY_TAG_ERROR = "ADD_CATEGORY_TAG_ERROR";

// Редактирование категории
export const EDIT_CATEGORY_TAG_REQUEST = "EDIT_CATEGORY_TAG_REQUEST";
export const EDIT_CATEGORY_TAG_SUCCESS = "EDIT_CATEGORY_TAG_SUCCESS";
export const EDIT_CATEGORY_TAG_ERROR = "EDIT_CATEGORY_TAG_ERROR";

// Удаление категории
export const DELETE_CATEGORY_TAG_REQUEST = "DELETE_CATEGORY_TAG_REQUEST";
export const DELETE_CATEGORY_TAG_SUCCESS = "DELETE_CATEGORY_TAG_SUCCESS";
export const DELETE_CATEGORY_TAG_ERROR = "DELETE_CATEGORY_TAG_ERROR";

//=====================================================
//_______________________PARTNERS______________________
//=====================================================
// Добавление координатов партнера
export const SET_PARTNER_COORDINATES_REQUEST = "SET_PARTNER_COORDINATES_REQUEST";
export const SET_PARTNER_COORDINATES_SUCCESS = "SET_PARTNER_COORDINATES_SUCCESS";
export const SET_PARTNER_COORDINATES_ERROR = "SET_PARTNER_COORDINATES_ERROR";

// Добавление зоны доставки партнера
export const SET_PARTNER_DELIVERY_ZONE_REQUEST = "SET_PARTNER_DELIVERY_ZONE_REQUEST";
export const SET_PARTNER_DELIVERY_ZONE_SUCCESS = "SET_PARTNER_DELIVERY_ZONE_SUCCESS";
export const SET_PARTNER_DELIVERY_ZONE_ERROR = "SET_PARTNER_DELIVERY_ZONE_ERROR";

// Копирование партнера
export const COPY_PARTNER_REQUEST = "COPY_PARTNER_REQUEST";
export const COPY_PARTNER_SUCCESS = "COPY_PARTNER_SUCCESS";
export const COPY_PARTNER_ERROR = "COPY_PARTNER_ERROR";

// Получение партнера
export const GET_PARTNER_REQUEST = "GET_PARTNER_REQUEST";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_ERROR = "GET_PARTNER_ERROR";

// Получение партнеров
export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_ERROR = "GET_PARTNERS_ERROR";

// Добавление партнера
export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_ERROR = "ADD_PARTNER_ERROR";

// Редактирование партнера
export const EDIT_PARTNER_REQUEST = "EDIT_PARTNER_REQUEST";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";
export const EDIT_PARTNER_ERROR = "EDIT_PARTNER_ERROR";

// Удаление партнера
export const DELETE_PARTNER_REQUEST = "DELETE_PARTNER_REQUEST";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_ERROR = "DELETE_PARTNER_ERROR";

// Сохранение партнера
export const SAVE_PARTNERS = "SAVE_PARTNERS";

//=====================================================
//_______________________PARTNER_TAGS__________________
//=====================================================

// Получение тэгов партнеров
export const GET_PARTNER_TAGS_REQUEST = "GET_PARTNER_TAGS_REQUEST";
export const GET_PARTNER_TAGS_SUCCESS = "GET_PARTNER_TAGS_SUCCESS";
export const GET_PARTNER_TAGS_ERROR = "GET_PARTNER_TAGS_ERROR";

// Добавление тэга партнеров
export const ADD_PARTNER_TAG_REQUEST = "ADD_PARTNER_TAG_REQUEST";
export const ADD_PARTNER_TAG_SUCCESS = "ADD_PARTNER_TAG_SUCCESS";
export const ADD_PARTNER_TAG_ERROR = "ADD_PARTNER_TAG_ERROR";

// Редактирование тэга партнеров
export const EDIT_PARTNER_TAG_REQUEST = "EDIT_PARTNER_TAG_REQUEST";
export const EDIT_PARTNER_TAG_SUCCESS = "EDIT_PARTNER_TAG_SUCCESS";
export const EDIT_PARTNER_TAG_ERROR = "EDIT_PARTNER_TAG_ERROR";

// Удаление тэга партнеров
export const DELETE_PARTNER_TAG_REQUEST = "DELETE_PARTNER_TAG_REQUEST";
export const DELETE_PARTNER_TAG_SUCCESS = "DELETE_PARTNER_TAG_SUCCESS";
export const DELETE_PARTNER_TAG_ERROR = "DELETE_PARTNER_TAG_ERROR";

//=====================================================
//________________PARTNER_DELIVERY_TYPE________________
//=====================================================

// Получение типов доставки
export const GET_PARTNER_DELIVERY_TYPES_REQUEST = "GET_PARTNER_DELIVERY_TYPES_REQUEST";
export const GET_PARTNER_DELIVERY_TYPES_SUCCESS = "GET_PARTNER_DELIVERY_TYPES_SUCCESS";
export const GET_PARTNER_DELIVERY_TYPES_ERROR = "GET_PARTNER_DELIVERY_TYPES_ERROR";

// Добавление типа доставки
export const ADD_PARTNER_DELIVERY_TYPE_REQUEST = "ADD_PARTNER_DELIVERY_TYPE_REQUEST";
export const ADD_PARTNER_DELIVERY_TYPE_SUCCESS = "ADD_PARTNER_DELIVERY_TYPE_SUCCESS";
export const ADD_PARTNER_DELIVERY_TYPE_ERROR = "ADD_PARTNER_DELIVERY_TYPE_ERROR";

//=====================================================
//___________________PARTNER_BRANDS____________________
//====================================================
// Получение
export const GET_BRAND_PARTNERS_REQUEST = "GET_BRAND_PARTNERS_REQUEST";
export const GET_BRAND_PARTNERS_SUCCESS = "GET_BRAND_PARTNERS_SUCCESS";
export const GET_BRAND_PARTNERS_ERROR = "GET_BRAND_PARTNERS_ERROR";

// Добавление
export const ADD_PARTNERS_TO_BRAND_REQUEST = "ADD_PARTNERS_TO_BRAND_REQUEST";
export const ADD_PARTNERS_TO_BRAND_SUCCESS = "ADD_PARTNERS_TO_BRAND_SUCCESS";
export const ADD_PARTNERS_TO_BRAND_ERROR = "ADD_PARTNERS_TO_BRAND_ERROR";

// Удаление
export const REMOVE_PARTNER_FROM_BRAND_REQUEST = "REMOVE_PARTNER_FROM_BRAND_REQUEST";
export const REMOVE_PARTNER_FROM_BRAND_SUCCESS = "REMOVE_PARTNER_FROM_BRAND_SUCCESS";
export const REMOVE_PARTNER_FROM_BRAND_ERROR = "REMOVE_PARTNER_FROM_BRAND_ERROR";

// Получение
export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_ERROR = "GET_BRANDS_ERROR";

// Добавление
export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_ERROR = "ADD_BRAND_ERROR";

// Редактирование
export const EDIT_BRAND_REQUEST = "EDIT_BRAND_REQUEST";
export const EDIT_BRAND_SUCCESS = "EDIT_BRAND_SUCCESS";
export const EDIT_BRAND_ERROR = "EDIT_BRAND_ERROR";

// Удаление
export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_ERROR = "DELETE_BRAND_ERROR";

//=====================================================
//_________________PRODUCT_CATEGORY____________________
//=====================================================

// Получение категорий товаров
export const GET_PRODUCT_CATEGORY_CHILD_REQUEST = "GET_PRODUCT_CATEGORY_CHILD_REQUEST";
export const GET_PRODUCT_CATEGORY_CHILD_SUCCESS = "GET_PRODUCT_CATEGORY_CHILD_SUCCESS";
export const GET_PRODUCT_CATEGORY_CHILD_ERROR = "GET_PRODUCT_CATEGORY_CHILD_ERROR";

// Получение категорий товаров
export const GET_PRODUCT_CATEGORY_REQUEST = "GET_PRODUCT_CATEGORY_REQUEST";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";

// Добавление категории товаров
export const ADD_PRODUCT_CATEGORY_REQUEST = "ADD_PRODUCT_CATEGORY_REQUEST";
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_ERROR = "ADD_PRODUCT_CATEGORY_ERROR";

// Редактирование категории товаров
export const EDIT_PRODUCT_CATEGORY_REQUEST = "EDIT_PRODUCT_CATEGORY_REQUEST";
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_ERROR = "EDIT_PRODUCT_CATEGORY_ERROR";

// Удаление категории товаров
export const DELETE_PRODUCT_CATEGORY_REQUEST = "DELETE_PRODUCT_CATEGORY_REQUEST";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_ERROR = "DELETE_PRODUCT_CATEGORY_ERROR";

//=====================================================
//_________________PARTNER_PRODUCST____________________
//=====================================================

// Получение товара партнера
export const GET_PARTNER_PRODUCTS_REQUEST = "GET_PARTNER_PRODUCTS_REQUEST";
export const GET_PARTNER_PRODUCTS_SUCCESS = "GET_PARTNER_PRODUCTS_SUCCESS";
export const GET_PARTNER_PRODUCTS_ERROR = "GET_PARTNER_PRODUCTS_ERROR";

// Добавление товара
export const ADD_PARTNER_PRODUCT_REQUEST = "ADD_PARTNER_PRODUCT_REQUEST";
export const ADD_PARTNER_PRODUCT_SUCCESS = "ADD_PARTNER_PRODUCT_SUCCESS";
export const ADD_PARTNER_PRODUCT_ERROR = "ADD_PARTNER_PRODUCT_ERROR";

// Редактирование товара
export const EDIT_PARTNER_PRODUCT_REQUEST = "EDIT_PARTNER_PRODUCT_REQUEST";
export const EDIT_PARTNER_PRODUCT_SUCCESS = "EDIT_PARTNER_PRODUCT_SUCCESS";
export const EDIT_PARTNER_PRODUCT_ERROR = "EDIT_PARTNER_PRODUCT_ERROR";

// Удаление товара
export const DELETE_PARTNER_PRODUCT_REQUEST = "DELETE_PARTNER_PRODUCT_REQUEST";
export const DELETE_PARTNER_PRODUCT_SUCCESS = "DELETE_PARTNER_PRODUCT_SUCCESS";
export const DELETE_PARTNER_PRODUCT_ERROR = "DELETE_PARTNER_PRODUCT_ERROR";

//=====================================================
//_________________PARTNER_PAYMENT_METHODS____________________
//=====================================================

// Получение способов оплаты партнера
export const GET_PARTNER_PAYMENT_METHODS_REQUEST = "GET_PARTNER_PAYMENT_METHODS_REQUEST";
export const GET_PARTNER_PAYMENT_METHODS_SUCCESS = "GET_PARTNER_PAYMENT_METHODS_SUCCESS";
export const GET_PARTNER_PAYMENT_METHODS_ERROR = "GET_PARTNER_PAYMENT_METHODS_ERROR";

//=====================================================
//_________________ADMIN_PARTNER_PRODUCST____________________
//=====================================================

// Получение товара партнера
export const GET_ADMIN_PARTNER_PRODUCTS_REQUEST = "GET_ADMIN_PARTNER_PRODUCTS_REQUEST";
export const GET_ADMIN_PARTNER_PRODUCTS_SUCCESS = "GET_ADMIN_PARTNER_PRODUCTS_SUCCESS";
export const GET_ADMIN_PARTNER_PRODUCTS_ERROR = "GET_ADMIN_PARTNER_PRODUCTS_ERROR";

//=========================================================
//_______________________USERS_____________________________
//=========================================================

// Запрос на роль пользователя
export const GET_USER_ROLE_REQUEST = "GET_USER_ROLE_REQUEST";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_ERROR = "GET_USER_ROLE_ERROR";

// Запрос списка пользователей
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

// Запрос на добавление пользователя
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

// Запрос на редактирование пользователя
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

// Запрос на удаление пользователя
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//=========================================================
//______________________CUSTOMERS__________________________
//=========================================================

// Запрос на получение информации о клиенте
export const GET_CUSTOMER_INFO_REQUEST = "GET_CUSTOMER_INFO_REQUEST";
export const GET_CUSTOMER_INFO_SUCCESS = "GET_CUSTOMER_INFO_SUCCESS";
export const GET_CUSTOMER_INFO_ERROR = "GET_CUSTOMER_INFO_ERROR";

// Запрос на получение клиентов
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

// Запрос на рассылку уведомлений клиентам
export const SEND_CUSTOMERS_NOTIFICATION_REQUEST = "SEND_CUSTOMERS_NOTIFICATION_REQUEST";
export const SEND_CUSTOMERS_NOTIFICATION_SUCCESS = "SEND_CUSTOMERS_NOTIFICATION_SUCCESS";
export const SEND_CUSTOMERS_NOTIFICATION_ERROR = "SEND_CUSTOMERS_NOTIFICATION_ERROR";

//=========================================================
//_______________________REPORTS___________________________
//=========================================================

// Запрос на получение отчета о клиентах
export const GET_CUSTOMERS_REPORT_REQUEST = "GET_CUSTOMERS_REPORT_REQUEST";
export const GET_CUSTOMERS_REPORT_SUCCESS = "GET_CUSTOMERS_REPORT_SUCCESS";
export const GET_CUSTOMERS_REPORT_ERROR = "GET_CUSTOMERS_REPORT_ERROR";

// Запрос на получение отчета о заказах
export const GET_ORDERS_REPORT_REQUEST = "GET_ORDERS_REPORT_REQUEST";
export const GET_ORDERS_REPORT_SUCCESS = "GET_ORDERS_REPORT_SUCCESS";
export const GET_ORDERS_REPORT_ERROR = "GET_ORDERS_REPORT_ERROR";

//=========================================================
//________________________BANNERS___________________________
//=========================================================

// Запрос на получение типов банеров
export const GET_BANNER_TYPES_REQUEST = "GET_BANNER_TYPES_REQUEST";
export const GET_BANNER_TYPES_SUCCESS = "GET_BANNER_TYPES_SUCCESS";
export const GET_BANNER_TYPES_ERROR = "GET_BANNER_TYPES_ERROR";

// Запрос на получение банеров
export const GET_BANNERS_REQUEST = "GET_BANNERS_REQUEST";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_ERROR = "GET_BANNERS_ERROR";

// Запрос на создание банера
export const CREATE_BANNER_REQUEST = "CREATE_BANNER_REQUEST";
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS";
export const CREATE_BANNER_ERROR = "CREATE_BANNER_ERROR";

// Запрос на редактирование банера
export const EDIT_BANNER_REQUEST = "EDIT_BANNER_REQUEST";
export const EDIT_BANNER_SUCCESS = "EDIT_BANNER_SUCCESS";
export const EDIT_BANNER_ERROR = "EDIT_BANNER_ERROR";

// Запрос на удаление банера
export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_ERROR = "DELETE_BANNER_ERROR";

//=========================================================
//______________________PROMOTIONS_________________________
//=========================================================

export const CLEAR_DATE = "CLEAR_DATE";

// Запрос на получение акций
export const GET_DISCOUNT_TYPES_REQUEST = "GET_DISCOUNT_TYPES_REQUEST";
export const GET_DISCOUNT_TYPES_SUCCESS = "GET_DISCOUNT_TYPES_SUCCESS";
export const GET_DISCOUNT_TYPES_ERROR = "GET_DISCOUNT_TYPES_ERROR";

// Запрос на получение акций
export const GET_PROMO_TYPES_REQUEST = "GET_PROMO_TYPES_REQUEST";
export const GET_PROMO_TYPES_SUCCESS = "GET_PROMO_TYPES_SUCCESS";
export const GET_PROMO_TYPES_ERROR = "GET_PROMO_TYPES_ERROR";

// Запрос на получение акций
export const GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST";
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_ERROR = "GET_PROMOTIONS_ERROR";

// Запрос на создание акции
export const CREATE_PROMOTION_REQUEST = "CREATE_PROMOTION_REQUEST";
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS";
export const CREATE_PROMOTION_ERROR = "CREATE_PROMOTION_ERROR";

// Запрос на редактирование акции
export const EDIT_PROMOTION_REQUEST = "EDIT_PROMOTION_REQUEST";
export const EDIT_PROMOTION_SUCCESS = "EDIT_PROMOTION_SUCCESS";
export const EDIT_PROMOTION_ERROR = "EDIT_PROMOTION_ERROR";

// Запрос на удаление акции
export const DELETE_PROMOTION_REQUEST = "DELETE_PROMOTION_REQUEST";
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_ERROR = "DELETE_PROMOTION_ERROR";

import {
  CLEAR_DATE,
  GET_DISCOUNT_TYPES_REQUEST,
  GET_DISCOUNT_TYPES_SUCCESS,
  GET_DISCOUNT_TYPES_ERROR,
  GET_PROMO_TYPES_REQUEST,
  GET_PROMO_TYPES_SUCCESS,
  GET_PROMO_TYPES_ERROR,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_ERROR,
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_ERROR,
  EDIT_PROMOTION_REQUEST,
  EDIT_PROMOTION_SUCCESS,
  EDIT_PROMOTION_ERROR,
  DELETE_PROMOTION_REQUEST,
  DELETE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_ERROR,
} from '../actions';

const INIT_STATE = {
  disountTypes: [],
  loadingDTypes: false,
  errorDTypes: null,

  promoTypes: [],
  loadingPTypes: false,
  errorPTypes: null,

  promotionsData: [],
  loading: false,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Получение
    case CLEAR_DATE:
      return {
        ...state,
        loadingDTypes: false,
        errorDTypes: null,
        promotionsData: [],
      };

    // Получение
    case GET_DISCOUNT_TYPES_REQUEST:
      return {
        ...state,
        loadingDTypes: true,
        errorDTypes: null,
      };

    case GET_DISCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        disountTypes: action.payload,
        loadingDTypes: false,
      };

    case GET_DISCOUNT_TYPES_ERROR:
      return {
        ...state,
        loadingDTypes: false,
        errorDTypes: action.payload,
      };
    
    // Получение
    case GET_PROMO_TYPES_REQUEST:
      return {
        ...state,
        loadingPTypes: true,
        errorPTypes: null,
      };

    case GET_PROMO_TYPES_SUCCESS:
      return {
        ...state,
        promoTypes: action.payload,
        loadingPTypes: false,
      };

    case GET_PROMO_TYPES_ERROR:
      return {
        ...state,
        loadingPTypes: false,
        errorPTypes: action.payload,
      };
    

    // Получение
    case GET_PROMOTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotionsData: action.payload,
        loading: false,
      };

    case GET_PROMOTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case CREATE_PROMOTION_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case CREATE_PROMOTION_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case CREATE_PROMOTION_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_PROMOTION_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_PROMOTION_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_PROMOTION_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_PROMOTION_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_PROMOTION_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as SvgSprite } from '../../images/sprite.svg';

import AppView from '../../pages';
import LoginPage from '../../pages/login-page';
import NotFoundPage from '../../pages/not-found-page';

import PrivateRoute from '../generic/PrivateRoute';

import { getAccessToken } from '../../services/authService';
import { setUserData } from '../../store/user/actions';
import useFirebase from 'hooks/useFirebase';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  pauseOnHover: true,
});

const App = () => {
  const dispatch = useDispatch();
  const token = getAccessToken();

  React.useEffect(() => {
    if (token?.length) {
      dispatch(setUserData(token));
    }
  }, [dispatch, token]);

  useFirebase();
  
  return (
    <>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <LoginPage />
          </Route>

          <PrivateRoute path="/">
            <AppView />
          </PrivateRoute>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
      <SvgSprite className="d-none" />
    </>
  );
};

export default App;

import React from 'react';
import { NavLink } from 'react-router-dom';

import { Layout, Menu, Button, Popconfirm, Badge } from "antd";
import { ExportOutlined } from '@ant-design/icons';
import { OrderContext } from '../AppLayout/AppLayout';
import NestedMenu from './NestedMenu';

import './AppSidebar.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const MainMenu = ({navItems, collapsed, userRole, marketId, isMobile, shortPath, fullPath, onCloseConnection}) => {
  const { ordersCount } = React.useContext(OrderContext);

  const TooltipNavItem = ({text}) => (
    <span className="tooltip-nav-item">{text}</span>
  );

  const logoutBtn = (
    <Popconfirm
      placement="bottomRight"
      title="Вы уверены, что хотите выйти ?"
      onConfirm={onCloseConnection}
      okText="ДА"
      cancelText="Нет"
      okButtonProps={{ danger: true }}
    >
      <Button
        className="btn-logout"
        type="link"
        icon={<ExportOutlined style={{ fontSize: '20px' }} />} size={'middle'}
      >
        Выйти из аккаунта
      </Button>
      {collapsed && <TooltipNavItem text='Выйти из аккаунта'/>}
    </Popconfirm>
  );

  const defaultMenuItem = (label, link, icon, childs, parentPath) => (
    <Menu.Item className="nav-items" key={link} icon={icon && icon}>
      <NavLink to={link}>
        {link === '/partner' ? marketId == 1 ? 'Меню' : 'Товары' : label}
        {ordersCount ? <Badge count={`${link === '/orders' || link === '/partnerOrders'? ordersCount : 0}`} className="custom-badge" overflowCount={99} /> : null}
      </NavLink>
      {(collapsed && !childs) && <TooltipNavItem text={label}/>}
      {childs && <NestedMenu navItems={childs} userRole={userRole} parentPath={parentPath}/>}
    </Menu.Item>
  );

  const mobileMenuItem = (label, link, icon) => {
    const pathSplit = fullPath.split('/');
    const pathRoot = pathSplit.length > 3 ? pathSplit.slice(0, 3).join('/') : pathSplit.join('/');
    return (
    <Menu.Item className={`nav-items ${link === pathRoot ? "ant-sub-menu-item-active" : ''}`} key={link} icon={icon && icon}>
      <NavLink to={link}>
        {link === '/partner' ? marketId == 1 ? 'Меню' : 'Товары' : label}
        {ordersCount ? <Badge count={`${link === '/orders' || link === '/partnerOrders'? ordersCount : 0}`} className="custom-badge" overflowCount={99} /> : null}
      </NavLink>
    </Menu.Item>
  )};

  const mobileSubMenuItem = (childs, label, link, icon) => (
    <SubMenu className="mobile-sub-items" key={link} icon={icon} title={label}>
      {childs.map(({label, link, icon, levelAccsess}) => (
        levelAccsess.includes(userRole) ? (
        mobileMenuItem(label, link, icon) ) : null
      ))}
    </SubMenu>
  );

  const onGetMobileMenuItem = (label, link, icon, childs) => (
    childs ? (
      mobileSubMenuItem(childs, label, link, icon)
      ) : (
      mobileMenuItem(label, link, icon)
    )
  );
 
  return (
    <Sider width={collapsed ? 70 : 190} className={`app-sidebar ${collapsed ? "collapsed-sidebar" : ""}`}>
      <Menu
        className="main-menu"
        mode="inline"
        selectedKeys={[`/${shortPath}`]}
        defaultSelectedKeys={[`/${shortPath}`]}
      >
        {navItems.map(({ label, link, icon, levelAccsess, childs, parentPath }) => (
          levelAccsess.includes(userRole) ? (
            isMobile ? (
              onGetMobileMenuItem(label, link, icon, childs) 
            ) : (
              defaultMenuItem(label, link, icon, childs, parentPath)
            ) 
          ) : null
        ))}

        <Menu.Item key="logout" className="nav-items btn-logout-wrapper">
          {logoutBtn}
        </Menu.Item>
      </Menu>
    </Sider>
  )
};

export default MainMenu;

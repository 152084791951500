import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import LoginForm from './LoginForm';

import { resetAuthData } from '../../services/authService'
import { loginUser } from '../../store/user/actions'

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error } = useSelector(({ user }) => user);

  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [checked, setChecked] = React.useState('');
  
  const onFinish = ({login, password, remember}) => {
    dispatch(loginUser({login, password, remember}))
      .then(({payload}) => {
        if (payload?.length > 0) {
          return history.push('/');
        }
        if (payload?.message?.length > 0) {
          return message.error('Введен неверный логин или пароль!', [1.7])
        } 
        return message.error('Проблемы с свервером попробуйте зайти позже!', [1.7])
      })
      .catch((error) => {
        message.error(`ERROR: ${error} `, [1.7])
        resetAuthData();
      })
  };

  const loginHandler = (evt) => setLogin(evt.target.value);
  const passwordHandler = (evt) => setPassword(evt.target.value);
  const checkHandler = (evt) => setChecked(evt.target.checked);
  const onIconRender = visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />);

  return (

    <LoginForm 
      onFinish={onFinish}
      loginHandler={loginHandler}
      passwordHandler={passwordHandler}
      checkHandler={checkHandler}
      onIconRender={onIconRender}
      loading={loading}
      error={error}
      login={login}
      password={password}
      checked={checked}
    />
  );
};

export default Login;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import MainMenu from './MainMenu';
import DrawerSidebar from './DrawerSidebar';

import { logoutUser } from 'store/user/actions';

import { UserDataContext } from 'pages';
import { WindowContext } from '../AppLayout/AppLayout';

import mainMenuItems from 'utils/mainMenuItems';

import './AppSidebar.scss';

const AppSidebar = ({connection, collapsed, toggleSidebar}) => {
  const { userRole, marketId } = React.useContext(UserDataContext);
  const { isTablet, isMobile } = React.useContext(WindowContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const shortPath = location?.pathname.split('/')[1];
  const fullPath = location?.pathname;
  const history = useHistory();

  const onCloseConnection = async () => {
    try {
      await connection.stop();
      return dispatch(logoutUser(history));
    } catch (err) {
      console.log("SignalR Disconnected:", err);
    }
  };

  const Menu = () =>
    <MainMenu 
      navItems={mainMenuItems} 
      userRole={userRole} 
      marketId={marketId} 
      isMobile={isMobile} 
      shortPath={shortPath} 
      fullPath={fullPath} 
      onCloseConnection={onCloseConnection}
    />
  ;

  const DefaultMenu = () =>
    <MainMenu 
      navItems={mainMenuItems} 
      userRole={userRole} 
      marketId={marketId} 
      shortPath={shortPath} 
      collapsed={collapsed}
      onCloseConnection={onCloseConnection}
    />
  ;
  
  // if (!loading) {
  //   return null
  // }

  return (
    <>
      {(isTablet || isMobile) ? ( 
        <DrawerSidebar 
          onDrawerClose={toggleSidebar} 
          toggler={collapsed} 
          isMobile={isMobile} 
          isTablet={isTablet} 
          userRole={userRole}
        >
          <Menu/>
        </DrawerSidebar> 
      ) : (
        <DefaultMenu/> 
      )}
    </>
  )
};

export default AppSidebar;

import {
  SET_ORDER_DATA_SUCCESS,
  GET_ORDER_DATA_REQUEST,
  GET_ORDER_DATA_SUCCESS,
  GET_ORDER_DATA_ERROR,
} from '../actions';

const INIT_STATE = {
  orderData: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Добавление
    case SET_ORDER_DATA_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
      };

    // Получение
    case GET_ORDER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_ORDER_DATA_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
        loading: false,
      };

    case GET_ORDER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import {
  GET_NEGATIVE_REVIEWS_REQUEST,
  GET_NEGATIVE_REVIEWS_SUCCESS,
  GET_NEGATIVE_REVIEWS_ERROR,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
  CONFIRM_REVIEW_REQUEST,
  CONFIRM_REVIEW_SUCCESS,
  CONFIRM_REVIEW_ERROR,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_ERROR,
} from '../actions';

const INIT_STATE = {

  negativeReviewsData: [],
  loadingNegative: false,
  errorNegative: null,

  reviewsData: [],
  loading: false,
  error: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_NEGATIVE_REVIEWS_REQUEST:
      return {
        ...state,
        loadingNegative: true,
        errorNegative: null,
      };

    case GET_NEGATIVE_REVIEWS_SUCCESS:
      return {
        ...state,
        negativeReviewsData: action.payload,
        loadingNegative: false,
      };

    case GET_NEGATIVE_REVIEWS_ERROR:
      return {
        ...state,
        loadingNegative: false,
        errorNegative: action.payload,
      };

    // Получение
    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewsData: action.payload,
        loading: false,
      };

    case GET_REVIEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Изменение
    case CONFIRM_REVIEW_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case CONFIRM_REVIEW_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case CONFIRM_REVIEW_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_REVIEW_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_REVIEW_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

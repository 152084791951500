import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModOrdersCount } from '../store/orders/actions';

const useGetOrdersCount = ({partnerId, isUpdateOrdersCount = ''}) => {
  const dispatch = useDispatch();
  const { modOrdersCount } = useSelector(({ orders }) => orders);
  const ordersCount =  modOrdersCount?.orderCount ?? 0
  const ref = useRef(isUpdateOrdersCount)
  useEffect(() => {
    dispatch(getModOrdersCount({
      isActive: true,
      isToday: true,
      IsPartnerOrders:  partnerId ? false : undefined 
    }));
  }, [partnerId, ref]);

  return {ordersCount, isUpdateOrdersCount};
};

export default useGetOrdersCount;

import {
  GET_CUSTOMER_INFO_REQUEST,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_CUSTOMER_INFO_ERROR,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  SEND_CUSTOMERS_NOTIFICATION_REQUEST,
  SEND_CUSTOMERS_NOTIFICATION_SUCCESS,
  SEND_CUSTOMERS_NOTIFICATION_ERROR,
} from '../actions';

const INIT_STATE = {

  customerData: [],
  loadingInfo: true,
  errorInfo: null,

  customersData: [],
  loading: true,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Получение
    case GET_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loadingInfo: true,
        errorInfo: null,
      };

    case GET_CUSTOMER_INFO_SUCCESS:

      return {
        ...state,
        customerData: action.payload,
        loadingInfo: false,
      };

    case GET_CUSTOMER_INFO_ERROR:
      return {
        ...state,
        loadingInfo: false,
        errorInfo: action.payload,
      };

    // Получение
    case GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersData: action.payload?.data,
        loading: false,
      };

    case GET_CUSTOMERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case SEND_CUSTOMERS_NOTIFICATION_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case SEND_CUSTOMERS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addedItem: action.payload,
        adding: false,
      };

    case SEND_CUSTOMERS_NOTIFICATION_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    default:
      return state;
  }
};

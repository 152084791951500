import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';

import { Layout, Badge, Button } from "antd";
import { ShoppingCartOutlined } from '@ant-design/icons';

import Icon from 'components/generic/Icon';
import CustomSidebarToggler from 'components/generic/CustomSidebarToggler';
import TopBarLogoRender from 'components/generic/TopBarLogoRender';

import { UserDataContext } from 'pages';
import { OrderContext, WindowContext } from '../AppLayout/AppLayout';

import convertFio from 'utils/convertFio';
import userRedirectPage from 'utils/userRedirectPage';

import './AppHeader.scss';

const { Header } = Layout;

const AppHeader = ({toggleSidebar, collapsed}) => {
  const { userRole, userFIO } = useContext(UserDataContext);
  const { ordersCount } = useContext(OrderContext);
  const { isTablet, isMobile, } = useContext(WindowContext);

  return (
    <Header className="app-header">
      <CustomSidebarToggler toggleSidebar={toggleSidebar} collapsed={collapsed}/>

      <TopBarLogoRender redirectPath={userRedirectPage[userRole]} isShortTitle={isMobile || isTablet}/>

      {(isMobile || isTablet) && (
        <NavLink className="logo-link-orders" to={`${userRole === 'Partner'  ? "/partnerOrders" : userRole === 'Courier' ? "/myorders": "/orders"}`}>
          <ShoppingCartOutlined className="orders-logo-icon" style={{fontSize: `${isMobile ? '26px' : '30px'}`}} />
          <Badge count={ordersCount} className="custom-badge" overflowCount={99} />
        </NavLink>
      )}

      <div className="user-login-wrapper">
        <Icon className="user-icon" name="user-circle-solid" color="#e8e8e8" />
        {!isMobile && (
          <span className="user-name">
            {convertFio(userFIO)}
          </span>
        )}
      </div>
    </Header>
  )
};

export default AppHeader;

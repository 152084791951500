import {
  SET_PARTNER_COORDINATES_REQUEST,
  SET_PARTNER_COORDINATES_SUCCESS,
  SET_PARTNER_COORDINATES_ERROR,
  SET_PARTNER_DELIVERY_ZONE_REQUEST,
  SET_PARTNER_DELIVERY_ZONE_SUCCESS,
  SET_PARTNER_DELIVERY_ZONE_ERROR,
  COPY_PARTNER_REQUEST,
  COPY_PARTNER_SUCCESS,
  COPY_PARTNER_ERROR,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_ERROR,
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_ERROR,
  GET_PARTNER_PAYMENT_METHODS_REQUEST,
  GET_PARTNER_PAYMENT_METHODS_SUCCESS,
  GET_PARTNER_PAYMENT_METHODS_ERROR,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_ERROR,
  EDIT_PARTNER_REQUEST,
  EDIT_PARTNER_SUCCESS,
  EDIT_PARTNER_ERROR,
  DELETE_PARTNER_REQUEST,
  DELETE_PARTNER_SUCCESS,
  DELETE_PARTNER_ERROR,
} from '../actions';

const INIT_STATE = {
  partnerCoordinates: [],
  setingPartnerCoords: false,
  errorPartnerCoords: null,

  deliveryZone: [],
  setingZone: false,
  errorZone: null,
  
  paymentMethodsData: [],
  loadingPaymentMethods: false,
  errorPaymentMethods: null,

  copyItem: null,
  copyng: false,
  errorCopyng: null,

  partnerData: null,
  loadingPartner: false,
  errorPartner: null,

  partnersData: [],
  partnerTotalCount: null,
  loading: false,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Добавление координатов партнера
    case SET_PARTNER_COORDINATES_REQUEST:
      return {
        ...state,
        setingPartnerCoords: true,
        errorPartnerCoords: null,
      };

    case SET_PARTNER_COORDINATES_SUCCESS:
      return {
        ...state,
        partnerCoordinates: action.payload,
        setingPartnerCoords: false,
      };

    case SET_PARTNER_COORDINATES_ERROR:
      return {
        ...state,
        setingPartnerCoords: false,
        errorPartnerCoords: action.payload,
      };

    // Добавление зоны доставки
    case SET_PARTNER_DELIVERY_ZONE_REQUEST:
      return {
        ...state,
        setingZone: true,
        errorZone: null,
      };

    case SET_PARTNER_DELIVERY_ZONE_SUCCESS:
      return {
        ...state,
        deliveryZone: action.payload,
        setingZone: false,
      };

    case SET_PARTNER_DELIVERY_ZONE_ERROR:
      return {
        ...state,
        setingZone: false,
        errorZone: action.payload,
      };

    // Копирование
    case COPY_PARTNER_REQUEST:
      return {
        ...state,
        copyng: true,
        errorCopyng: null,
      };

    case COPY_PARTNER_SUCCESS:
      return {
        ...state,
        copyItem: action.payload,
        copyng: false,
      };

    case COPY_PARTNER_ERROR:
      return {
        ...state,
        copyng: false,
        errorCopyng: action.payload,
      };

    // Получение
    case GET_PARTNER_REQUEST:
      return {
        ...state,
        loadingPartner: true,
        errorPartner: null,
      };

    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        partnerData: action.payload,
        loadingPartner: false,
      };

    case GET_PARTNER_ERROR:
      return {
        ...state,
        loadingPartner: false,
        errorPartner: action.payload,
      };      

    // Получение
    case GET_PARTNER_PAYMENT_METHODS_REQUEST:      
      return {
        ...state,
        loadingPartner: true,
        errorPartner: null,
      };

    case GET_PARTNER_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethodsData: action.payload,
        loadingPartner: false,
      };

    case GET_PARTNER_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loadingPartner: false,
        errorPartner: action.payload,
      };      

    // Получение
    case GET_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnersData: action.payload?.data,
        partnerTotalCount: action.payload?.totalCount,
        loading: false,
      };

    case GET_PARTNERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_PARTNER_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_PARTNER_SUCCESS:
      return {
        ...state,
        addedItem: action?.payload,
        adding: false,
      };

    case ADD_PARTNER_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_PARTNER_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_PARTNER_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_PARTNER_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_PARTNER_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_PARTNER_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};

import React, {useEffect} from 'react';
import { Drawer } from 'antd';

import CustomSidebarToggler from '../../generic/CustomSidebarToggler';
import TopBarLogoRender from '../../generic/TopBarLogoRender';
import userRedirectPage from 'utils/userRedirectPage';

import './style.scss';

const DrawerModal = ({ onDrawerClose, toggler, userRole, isTablet, isMobile, children }) => {
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => setVisible(true);
  const onClose = React.useCallback(() => {
    setVisible(false);
    onDrawerClose();
  }, []);

  const Title = () => (
    <>
      <CustomSidebarToggler toggleSidebar={onClose} collapsed={toggler}/>
      <TopBarLogoRender redirectPath={userRedirectPage[userRole]} isShortTitle={isMobile || isTablet}/>
    </>
  )

  useEffect(() => {
    if(toggler) {
      showDrawer()
    } 
  }, [toggler])

  return (
    <>
      <Drawer
        title={<Title/>}
        placement="left"
        width={'100vw'}
        closable={false}
        visible={visible}
        className="drawer-sidebar"
      >
        {children}
        <div 
          className="custom-drawer-close-mask"
          onClick={onClose}
        ></div>
      </Drawer>
    </>
  );
};


export default DrawerModal;

import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

const AVAILABLE_SIZES = {
  desktop: 1200,
  tablet: 1024,
  mobile: 750,
}

const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    width: AVAILABLE_SIZES.desktop,
    height: AVAILABLE_SIZES.desktop,
  })

  useEffect(() => {
    const handleResize = throttle(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 300)

    handleResize()
    window.addEventListener('resize', handleResize, {passive: true})
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    dimensions: {
      width: windowSize.width,
      height: windowSize.height,
    },
    isMobile: windowSize.width <= AVAILABLE_SIZES.mobile,
    isTablet: windowSize.width <= AVAILABLE_SIZES.tablet,
    isdesktop: windowSize.width >= AVAILABLE_SIZES.desktop,
  }
}

export default useWindowResize


// type UseWindowResizeType = {
//   dimensions: {
//     height: number
//     width: number
//   }
//   isMobile: boolean
//   isTablet: boolean
// }

// const useWindowResize = (): UseWindowResizeType => {
//   const [windowSize, setWindowSize] = useState<UseWindowResizeType['dimensions']>({
import React, { useCallback, useEffect, useState } from 'react';
import { notification } from "antd";
import * as signalR from '@microsoft/signalr';
import signalFile from 'sound/Sound_11086.wav';
import useSound from 'use-sound';

const useConectionWebSockets = ({token, partnerId = '', userId, userRole}) => {
  const [play] = useSound(signalFile);
  const handleClick = useCallback(() => play(), [play]);
  const [connection, setConnection] = React.useState(null);
  const [isUpdateOrdersCount, setIsUpdateOrdersCount] = React.useState('');

  const connectionHub = `${process.env.REACT_APP_HUB_URL}orderHub?access_token=${token}&userId=${partnerId?.length == 0 ? userId : partnerId}`;
  const transport = signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling;
  const options = {
    transport,
  };

  const [newOrder, setNewOrder] = useState("");

  useEffect(() => {
    if(!!newOrder && newOrder.trim().length) {
      if(userRole !== 'Courier') {
        notification.info({
          message: 'Новый Заказ!',
          description: newOrder,
          duration: 6
        });
        setIsUpdateOrdersCount(newOrder);      
        handleClick();
      } 
    }
  }, [newOrder, userRole])

  const [updateOrders, setUpdateOrders] = React.useState('');

  useEffect(() => {
    if(!!updateOrders && updateOrders.trim().length && /{([\s\S]+?)}/i.test(updateOrders)) {
      const order = JSON.parse(updateOrders);

      const descriptionOrder = (
        <>
          {order.NotificationType === "Error" ? (
            <div>
              {order.RoleName} {order.UserName} отменил заказа № {order.OrderNumber} <br/>
              Адрес - {order.Address}
            </div>
          ) : (
            <div>
              {order.RoleName} {order.UserName} сменил статус заказа на {order.NewOrderStatus}<br/>
              Заказа № {order.OrderNumber}<br/>
              Адрес - {order.Address}
            </div>
          )}
        </>  
      );

      if(order) {
        notification.warning({
          message: "Заказ " + order.NewOrderStatus + "!",
          description: descriptionOrder,
          duration: order.NewOrderStatus.toLowerCase() === "отменен" ? 0 : 6
        });
      };
      setIsUpdateOrdersCount(updateOrders);    
    }
  }, [updateOrders])

  const startSignalRConnection = React.useCallback(async () => {
    try {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
        .build();

      if (userRole === "SuperAdministrator") {
        connection.on("OrderNotification", setNewOrder);
        connection.on("OrderStatusNotification", setUpdateOrders);
        // connection.on("PastOrderNotification", getPastOrderNotification);
      } 
      if (userRole === "Administrator" || userRole === "Moderator") {
        connection.on("CityOrderNotification", setNewOrder);
        connection.on("OrderStatusNotification", setUpdateOrders);
      } 
      if (userRole === "Partner") {
        connection.on("PartnerOrderNotification", setNewOrder);
        // connection.on("PartnerOrderStatusNotification", updateStatusOrderNotification);
      } 
      if (userRole === "Courier") {
        connection.on("EmployeeOrderNotification", setNewOrder);
      }

      connection.onclose(() => {
        setConnection(null);
      });

      await connection.start().then(function () {
        connection.invoke('GetConnectionId');
      });
      setConnection(connection);
    } catch (err) {
      console.log("SignalR Disconnected:");
    }
  }, [token]);

  useEffect(() => {
    token.length && startSignalRConnection();
  }, [token]);

  return {connection, updateOrders, newOrder, isUpdateOrdersCount};
};

export default useConectionWebSockets;


// logMessageContent: true,
// logger: signalR.LogLevel.Trace,
// accessTokenFactory: () => action.user.access_token

// const onNotifReceived = (res, type, title) => {
//   if(res.length) {
//     notification[type]({
//       message: title,
//       description: res,
//       duration: 6
//     });
//     handleClick();
//     dispatch(getModOrdersCount({isActive: true, isToday: true}));
//     title === 'Непринятый заказ!' && setUpdateOrders(res);
//   };
// };
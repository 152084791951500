import axios from '../../services/apiService';

import {
  SET_NEW_COURIER_ORDERS_SUCCESS,
  SET_COURIER_FB_TOKEN_REQUEST,
  SET_COURIER_FB_TOKEN_SUCCESS,
  SET_COURIER_FB_TOKEN_ERROR,
  GET_COURIER_DATA_REQUEST,
  GET_COURIER_DATA_SUCCESS,
  GET_COURIER_DATA_ERROR,
  GET_COURIER_ACTIVE_ORDERS_REQUEST,
  GET_COURIER_ACTIVE_ORDERS_SUCCESS,
  GET_COURIER_ACTIVE_ORDERS_ERROR,
  GET_COURIER_ARCHIVE_ORDERS_REQUEST,
  GET_COURIER_ARCHIVE_ORDERS_SUCCESS,
  GET_COURIER_ARCHIVE_ORDERS_ERROR,
  GET_COURIER_STATUSES_REQUEST,
  GET_COURIER_STATUSES_SUCCESS,
  GET_COURIER_STATUSES_ERROR,
  EDIT_COURIER_ORDER_STATUS_REQUEST,
  EDIT_COURIER_ORDER_STATUS_SUCCESS,
  EDIT_COURIER_ORDER_STATUS_ERROR,
  SET_COURIER_STATUS_REQUEST,
  SET_COURIER_STATUS_SUCCESS,
  SET_COURIER_STATUS_ERROR,
} from '../actions';


// Обновить список всех заказов
const setNewOrders = (responseData) => {
  return ({
    type: SET_NEW_COURIER_ORDERS_SUCCESS,
    payload: responseData,
  })
};

// Получение информации о курьере
const getCourierDataRequested = () => ({
  type: GET_COURIER_DATA_REQUEST,
});

const getCourierDataSuccess = (responseData) => ({
  type: GET_COURIER_DATA_SUCCESS,
  payload: responseData,
});

const getCourierDataError = (error) => ({
  type: GET_COURIER_DATA_ERROR,
  payload: error,
});

const getCourierDataRequest = async () => {
  return axios.get(`EmployeePage/GetEmployeeData`).then((response) => response.data);
};

const getCourierData = () => (dispatch) => {
  dispatch(getCourierDataRequested());
  return getCourierDataRequest()
    .then((data) => dispatch(getCourierDataSuccess(data)))
    .catch((error) => dispatch(getCourierDataError(error)));
};

// Получение активных заказов курьера
const getCourierActiveOrdersRequested = () => ({
  type: GET_COURIER_ACTIVE_ORDERS_REQUEST,
});

const getCourierActiveOrdersSuccess = (responseData) => ({
  type: GET_COURIER_ACTIVE_ORDERS_SUCCESS,
  payload: responseData,
});

const getCourierActiveOrdersError = (error) => ({
  type: GET_COURIER_ACTIVE_ORDERS_ERROR,
  payload: error,
});

const getCourierActiveOrdersRequest = async () => {
  const params = {isActive: true}
  return axios.get(`EmployeePage/GetEmployeeOrders`, { params }).then((response) => response.data);
};

const getCourierActiveOrders = () => (dispatch) => {
  dispatch(getCourierActiveOrdersRequested());
  return getCourierActiveOrdersRequest()
    .then((data) => dispatch(getCourierActiveOrdersSuccess(data)))
    .catch((error) => dispatch(getCourierActiveOrdersError(error)));
};

// Получение статусов курьера
const getCourierStatusesRequested = () => ({
  type: GET_COURIER_STATUSES_REQUEST,
});

const getCourierStatusesSuccess = (responseData) => ({
  type: GET_COURIER_STATUSES_SUCCESS,
  payload: responseData,
});

const getCourierStatusesError = (error) => ({
  type: GET_COURIER_STATUSES_ERROR,
  payload: error,
});

const getCourierStatusesRequest = async () => {
  return axios.get(`EmployeePage/GetEmployeeStatuses`).then((response) => response.data);
};

const getCourierStatuses = () => (dispatch) => {
  dispatch(getCourierStatusesRequested());
  return getCourierStatusesRequest()
    .then((data) => dispatch(getCourierStatusesSuccess(data)))
    .catch((error) => dispatch(getCourierStatusesError(error)));
};

// Изменение
const editOrderStatusRequested = () => ({
  type: EDIT_COURIER_ORDER_STATUS_REQUEST,
});

const editOrderStatusSuccess = (data) => ({
  type: EDIT_COURIER_ORDER_STATUS_SUCCESS,
  payload: data,
});

const editOrderStatusError = (error) => ({
  type: EDIT_COURIER_ORDER_STATUS_ERROR,
  payload: error,
});

const editOrderStatusRequest = async (values) => {
  return axios.put(`EmployeePage/SetEmployeeOrderStatus`, values).then((response) => response.data);
};

const editOrderStatus = (values) => (dispatch) => {
  dispatch(editOrderStatusRequested());
  return editOrderStatusRequest(values)
    .then((values) => {
      dispatch(editOrderStatusSuccess(values));
      return 'Данные успешно изменены';
    })
    .catch((error) => {
      dispatch(editOrderStatusError(error));
      throw error;
    });
};

// Изменение
const setCourierStatusRequested = () => ({
  type: SET_COURIER_STATUS_REQUEST,
});

const setCourierStatusSuccess = (data) => ({
  type: SET_COURIER_STATUS_SUCCESS,
  payload: data,
});

const setCourierStatusError = (error) => ({
  type: SET_COURIER_STATUS_ERROR,
  payload: error,
});

const setCourierStatusRequest = async (values) => {
  return axios.put(`EmployeePage/SetEmployeeStatus`, values).then((response) => response.data);
};

const setCourierStatus = (values) => (dispatch) => {
  dispatch(setCourierStatusRequested());
  return setCourierStatusRequest(values)
    .then((values) => {
      dispatch(setCourierStatusSuccess(values));
      return 'Статус работы изменен';
    })
    .catch((error) => {
      dispatch(setCourierStatusError(error));
      throw error;
    });
};

// Установить firebase токен курьера в базу
const setCourierFbTokenRequested = () => ({
  type: SET_COURIER_FB_TOKEN_REQUEST,
});

const setCourierFbTokenSuccess = (responseData) => ({
  type: SET_COURIER_FB_TOKEN_SUCCESS,
  payload: responseData,
});

const setCourierFbTokenError = (error) => ({
  type: SET_COURIER_FB_TOKEN_ERROR,
  payload: error,
});

const setCourierFbTokenRequest = async (values) => {
  return axios.put(`EmployeePage/SetEmployeeFbToken`, values).then((response) => response.data);
};

const setCourierFbToken = (values) => (dispatch) => {
  dispatch(setCourierFbTokenRequested());
  return setCourierFbTokenRequest(values)
    .then((data) => dispatch(setCourierFbTokenSuccess(data)))
    .catch((error) => dispatch(setCourierFbTokenError(error)));
};

export { setNewOrders, setCourierFbToken, getCourierData, getCourierActiveOrders, getCourierStatuses, editOrderStatus, setCourierStatus };

import {
  GET_BRAND_PARTNERS_REQUEST,
  GET_BRAND_PARTNERS_SUCCESS,
  GET_BRAND_PARTNERS_ERROR,
  REMOVE_PARTNER_FROM_BRAND_REQUEST,
  REMOVE_PARTNER_FROM_BRAND_SUCCESS,
  REMOVE_PARTNER_FROM_BRAND_ERROR,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
  ADD_BRAND_REQUEST,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_ERROR,
  EDIT_BRAND_REQUEST,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_ERROR,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_ERROR,
} from '../actions';

const INIT_STATE = {
  brandData: [],
  loadingBrand: false,
  errorBrand: null,

  brandsData: [],
  loading: false,
  error: null,

  addedItem: null,
  adding: false,
  errorAdding: null,

  removeItem: null,
  removing: false,
  errorRemove: null,

  editItem: null,
  editing: false,
  errorEditing: null,

  deletedItem: null,
  deleting: true,
  errorDeleting: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Получение
    case GET_BRAND_PARTNERS_REQUEST:
      return {
        ...state,
        loadingBrand: true,
        errorBrand: null,
      };

    case GET_BRAND_PARTNERS_SUCCESS:
      return {
        ...state,
        brandData: action.payload.data,
        loadingBrand: false,
      };

    case GET_BRAND_PARTNERS_ERROR:
      return {
        ...state,
        loadingBrand: false,
        errorBrand: action.payload,
      };

    // Удаление
    case REMOVE_PARTNER_FROM_BRAND_REQUEST:
      return {
        ...state,
        removing: true,
        errorRemove: null,
      };

    case REMOVE_PARTNER_FROM_BRAND_SUCCESS:
      return {
        ...state,
        removeItem: action.payload,
        removing: false,
      };

    case REMOVE_PARTNER_FROM_BRAND_ERROR:
      return {
        ...state,
        removing: false,
        errorRemove: action.payload,
      };

    // Получение
    case GET_BRANDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brandsData: action.payload,
        loading: false,
      };

    case GET_BRANDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление
    case ADD_BRAND_REQUEST:
      return {
        ...state,
        adding: true,
        errorAdding: null,
      };

    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        addedItem: action?.payload,
        adding: false,
      };

    case ADD_BRAND_ERROR:
      return {
        ...state,
        adding: false,
        errorAdding: action.payload,
      };

    // Изменение
    case EDIT_BRAND_REQUEST:
      return {
        ...state,
        editing: true,
        errorEditing: null,
      };

    case EDIT_BRAND_SUCCESS:
      return {
        ...state,
        editItem: action.payload,
        editing: false,
      };

    case EDIT_BRAND_ERROR:
      return {
        ...state,
        editing: false,
        errorEditing: action.payload,
      };

    // Удаление
    case DELETE_BRAND_REQUEST:
      return {
        ...state,
        deleting: true,
        errorDeleting: null,
      };

    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        deletedItem: action.payload,
        deleting: false,
      };

    case DELETE_BRAND_ERROR:
      return {
        ...state,
        deleting: false,
        errorDeleting: action.payload,
      };

    default:
      return state;
  }
};
